import { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  FormInput,
  FormLabel,
  FormTextarea,
} from "../../../base-components/Form";

import { PreviewComponent } from "../../../base-components/PreviewComponent";

import CustomFormInput from "../../../custom-components/CustomFormInput";
import Loading from "../../../components/Loading";
import Table from "../../../base-components/Table";
import { dateFormat } from "../../../helper/dateFormat";

const CarRentalBookingDetails = () => {
  const location = useLocation();
  const [bookingDetails] = useState(location.state);
  const [loading, setLoading] = useState<boolean>(false);
  let {
    car_details,
    additional_prices,
    car_protection,
    pick_up_location,
    drop_off_location,
  } = bookingDetails;

  if (car_details) car_details = JSON.parse(car_details || {});
  if (pick_up_location) pick_up_location = JSON.parse(pick_up_location  || {});
  if (drop_off_location) drop_off_location = JSON.parse(drop_off_location || {});
  if (car_protection) car_protection = JSON.parse(car_protection || {});
  if (additional_prices) additional_prices = JSON.parse(additional_prices || {});

  const summedPrices = additional_prices.reduce((acc, item) => {
    const { additional_type, additional_price, additional_price_name } = item;
    const price = parseFloat(additional_price);

    if (!acc[additional_type]) {
      acc[additional_type] = {};
    }

    if (!acc[additional_type][additional_price_name]) {
      acc[additional_type][additional_price_name] = 0;
    }

    acc[additional_type][additional_price_name] += price;

    return acc;
  }, {});

  const percentagePrice = summedPrices.percentage;
  const regularPrice = summedPrices.regular;

  // console.log("summedPrices", summedPrices);

  // console.log("bookingDetails :", bookingDetails);
  // console.log("car_details :", car_details);

  return (
    <>
      {loading === true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">Complete Booking Details</h1>
        </div>
        <div className={"flex flex-col items-center py-5 px-8"}>
          <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
            <CustomFormInput
              label="Booking Id"
              name="booking_id"
              type="text"
              value={bookingDetails.booking_id}
              disabled={true}
            />

            <CustomFormInput
              label="Booking Date"
              name="booking_date"
              type="text"
              value={dateFormat(bookingDetails.booking_date)}
              disabled={true}
            />
            <CustomFormInput
              label="Paid Status"
              name="paid_status"
              type="text"
              value={bookingDetails?.paid_status}
              disabled={true}
            />
            <CustomFormInput
              label="Car Rental User Id"
              name="car_rental_userId
"
              type="text"
              value={bookingDetails?.car_rental_userId}
              disabled={true}
            />
            <CustomFormInput
              label="Paid by"
              name="paid_by"
              type="text"
              value={bookingDetails?.paid_by}
              disabled={true}
            />
          </div>

          <div className="title my-3 w-full col-span-6">
            <div className=" text-2xl text-gray-600 font-bold mt-4">
              Car Details
            </div>
          </div>

          <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="car_name">
                Car Name
              </FormLabel>
              <FormInput
                type="text"
                id="car_name"
                name="car_name"
                value={car_details?.car_name}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="car_category">
                Car Category
              </FormLabel>
              <FormInput
                type="text"
                id="car_category"
                name="car_category"
                value={car_details?.car_category}
                disabled={true}
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="car_company">
                Car Company
              </FormLabel>
              <FormInput
                type="text"
                id="car_company"
                name="car_company"
                value={car_details?.car_company}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="model">
                Model
              </FormLabel>
              <FormInput
                type="text"
                id="model"
                name="model"
                value={car_details?.model}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="color">
                Car Color
              </FormLabel>
              <FormInput
                type="text"
                id="color"
                name="color"
                value={car_details?.color}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="ratings">
                Ratings
              </FormLabel>
              <FormInput
                type="text"
                id="ratings"
                name="ratings"
                value={car_details?.ratings}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="discount">
                Discount
              </FormLabel>
              <FormInput
                type="text"
                id="discount"
                name="discount"
                value={car_details?.discount}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="discount_type">
                Discount Type
              </FormLabel>
              <FormInput
                type="text"
                id="discount_type"
                name="discount_type"
                value={car_details?.discount_type}
                disabled={true}
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <FormLabel
                className="text-gray-500"
                htmlFor="cancellation_end_time"
              >
                Cancellation End Time
              </FormLabel>
              <FormInput
                type="text"
                id="cancellation_end_time"
                name="cancellation_end_time"
                value={car_details?.cancellation_end_time}
                disabled={true}
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="Luggage_space">
                Luggage
              </FormLabel>
              <FormInput
                type="number"
                min={0}
                id="Luggage_space"
                name="Luggage_space"
                value={car_details.Luggage_space}
                disabled={true}
              />
            </div>

            <div className="col-span-12 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="door">
                Doors
              </FormLabel>
              <FormInput
                type="number"
                id="door"
                name="door"
                value={car_details.door}
                disabled={true}
              />
            </div>

            <div className="col-span-12 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="gear">
                Gear
              </FormLabel>
              <FormInput
                type="number"
                id="gear"
                name="gear"
                value={car_details.gear}
                disabled={true}
              />
            </div>

            {/* <div className="col-span-4 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="price_per_km">
                Price/km
              </FormLabel>
              <FormInput
                type="number"
                id="price_per_km"
                name="price_per_km"
                value={car_details.price_per_km}
                disabled={true}
              />
            </div> */}
{/* 
            <div className="col-span-4 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="seats">
                Seats
              </FormLabel>
              <FormInput
                type="number"
                id="seats"
                name="seats"
                value={car_details.seats}
                disabled={true}
              />
            </div> */}

            <div className="col-span-12 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="ac">
                AC
              </FormLabel>
              <FormInput
                id="ac"
                name="ac"
                value={car_details.ac ? "true" : "false"}
                disabled={true}
              />
            </div>

            {/* <div className="col-span-6 md:col-span-3">
              <FormLabel className="text-gray-500" htmlFor="auto">
                Auto
              </FormLabel>
              <FormInput
                id="auto"
                name="auto"
                value={car_details.auto ? "auto" : "manual"}
                disabled={true}
              />
            </div> */}
            <CustomFormInput
              label="Pickup Date"
              name="pickup_date"
              type="text"
              value={dateFormat(bookingDetails?.pickup_date)}
              disabled={true}
            />
            <CustomFormInput
              label="Drop off Date"
              name="dropoff_date"
              type="text"
              value={dateFormat(bookingDetails?.dropoff_date)}
              disabled={true}
            />
            <CustomFormInput
              label="Pickup Location"
              name="pick_up_location"
              type="text"
              value={pick_up_location?.place}
              disabled={true}
            />
            <CustomFormInput
              label="Drop off Location"
              name="drop_off_location"
              type="text"
              value={drop_off_location?.place}
              disabled={true}
            />

            {/* <div className="col-span-6 mt-3"> */}
            {/* 
              <FormLabel className="text-gray-500" htmlFor="description">
                Description
              </FormLabel>

              <FormTextarea
                id="description"
                name="description"
                rows={3}
                value={car_details.description}
                disabled={true}
              /> */}

            {/* Additional Prices  */}
            {/* <div className="title mt-3 w-full col-span-6">
                <div className=" text-2xl text-gray-600 font-bold mt-4">
                  Additional Price
                </div>
              </div> */}
            <div className="col-span-12 md:col-span-12">
              <div className="overflow-x-auto">
                <Table hover className="w-full mt-5">
                  <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
                    <Table.Tr>
                      <Table.Th className="whitespace-nowrap">
                        Price per day
                      </Table.Th>
                      <Table.Th className="whitespace-nowrap">
                        Admin Commission
                      </Table.Th>

                      <Table.Th className="whitespace-nowrap">
                        Total Amount
                      </Table.Th>
                      <Table.Th className="whitespace-nowrap">
                        Additional Prices
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    <Table.Tr className="text-center text-xs">
                      <Table.Td>{bookingDetails?.price_per_day}</Table.Td>
                      <Table.Td>{bookingDetails?.admin_comission}</Table.Td>

                      {/* <Table.Td>{JSON.stringify(summedPrices)}</Table.Td> */}
                      <Table.Td>
                        {Object.keys(percentagePrice).map((key, idx) => {
                          return (
                            <div>
                              {percentagePrice[key]}% - {key}
                            </div>
                          );
                        })}

                        {Object.keys(regularPrice || {}).map((key, idx) => {
                          return (
                            <div>
                              {percentagePrice[key]} SAR - {key}
                            </div>
                          );
                        })}
                      </Table.Td>
                      <Table.Td>{bookingDetails?.total_price}</Table.Td>
                    </Table.Tr>
                    {/* );
                      })} */}
                  </Table.Tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </PreviewComponent>
    </>
  );
};

export default CarRentalBookingDetails;
