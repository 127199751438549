import { FormInput, FormSelect } from "../../../base-components/Form";

// Function to handle changes in the additional_price items
const handleAdditionalPriceChange = (meta, setMeta, index, e) => {
  const newAdditionalPrices = [...meta.additional_price];
  newAdditionalPrices[index] = {
    ...newAdditionalPrices[index],
    [e.target.name]: e.target.value,
  };
  setMeta({ ...meta, additional_price: newAdditionalPrices });
};

// Function to add a new additional_price item
export const addAdditionalPrice = (meta, setMeta) => {
  const newAdditionalPrice = {
    id: Date.now(),
    label: "",
    price: "",
    type: "",
  };
  setMeta({
    ...meta,
    additional_price: [...meta.additional_price, newAdditionalPrice],
  });
};

//  Function to remove an additional price
const removeAdditionalPrice = (meta: any, setMeta: any, index: any) => {
  const newAdditionalPrices = [...meta.additional_price];
  newAdditionalPrices.splice(index, 1);
  setMeta({ ...meta, additional_price: newAdditionalPrices });
};

// Rendering additional prices
const AdditionalPrices = ({ meta, setMeta, isViewPage }: any) => {
  console.log("meta", meta);
  return meta.additional_price?.map((price: any, index: any) => (
    <div key={price.id} className="grid grid-cols-6 gap-3 mt-3">
      <FormInput
        type="text"
        name="label"
        placeholder="Label"
        value={price.label}
        onChange={(e) => handleAdditionalPriceChange(meta, setMeta, index, e)}
        disabled={isViewPage}
      />
      <FormInput
        type="text"
        name="price"
        placeholder="Price"
        value={price.price}
        onChange={(e) => handleAdditionalPriceChange(meta, setMeta, index, e)}
        disabled={isViewPage}
      />

      <FormSelect
        name="type"
        value={price.type}
        onChange={(e:any) => handleAdditionalPriceChange(meta, setMeta, index, e)}
        disabled={isViewPage}
      >
        <option value="percentage">Percentage</option>
        <option value="fixed">Fixed</option>
      </FormSelect>

      {!isViewPage ? (
        <button
          className="flex items-center text-danger"
          onClick={() => removeAdditionalPrice(meta, setMeta, index)}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      ) : (
        ""
      )}
    </div>
  ));
};

export default AdditionalPrices;
