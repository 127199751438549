import React from "react";
import { FormLabel, FormSelect } from "../base-components/Form";

interface Option {
  value: string | number | Boolean;
  label: string;
}

interface DropdownProps {
  name: string;
  value: string | number | Boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  threeCol?: Boolean;
  label : string
  disabled: Boolean
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  value,
  label,
  onChange,
  options,
  threeCol,
  disabled
}) => {
  return (
    <div
      className={
        threeCol ? "col-span-4 md:col-span-2" : "col-span-6 md:col-span-3"
      }
    >
      <FormLabel className="text-gray-500" htmlFor={name}>
        {label}
      </FormLabel>
      <FormSelect id={name} name={name} value={value} onChange={onChange} disabled={disabled}>
        {options.map((option, index) => {
          return (
            <option key={index} value={String(option.value)}>
              {option.label}
            </option>
          );
        })}
      </FormSelect>
    </div>
  );
};

export default Dropdown;
