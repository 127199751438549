export default function calculateAdminCommission(
  amount: number,
  commissionPercentage: number
): number | null {
  // Ensure the inputs are valid numbers
  if (isNaN(amount) || isNaN(commissionPercentage)) {
    console.error(
      "Invalid input. Please enter valid numbers for sales amount and commission rate."
    );
    return null;
  }

  const commission: number = amount * (commissionPercentage / 100);

  return commission;
}
