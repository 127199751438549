import { ChangeEvent, useRef, useState, useEffect } from "react";
import { PreviewComponent } from "../../../base-components/PreviewComponent";

import toast from "react-hot-toast";
import Button from "../../../base-components/Button";
import Loading from "../../../components/Loading";

import CustomFormInput from "../../../custom-components/CustomFormInput";
import axios from "axios";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { useNavigate } from "react-router-dom";
import { useDecodedToken } from "../../../hooks/useDecodedToken";

interface Props {
  mode: string;
}
const CategoryForm: React.FC<Props> = ({ mode }) => {
  const [isAddingCar, setIsAddingCar] = useState<boolean>(false);

  const [meta, setMeta] = useState<any>({
    name: "",
  });
  const navigate = useNavigate();

  const onMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setMeta((prevMeta: any) => ({
      ...prevMeta,
      [name]: value,
    }));
  };

  const decodedToken = useDecodedToken();

  const onAddCategory = async () => {
    if (!decodedToken) return console.log("decoded token is null");

    // if (!isAddingCar) {

    if (meta.name === "") {
      toast.error("Category Name is required");
    } else {
      setIsAddingCar(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/car-rental/category/${decodedToken?.id}`,
          meta
        );
        if (response.status === 201) {
          // const data = await response.json();
          toast.success("Car Category added successfully!");
          navigate("/car-category");
          setMeta({ name: "" });
        } else {
          toast.error("Failed to add car rental..");
        }
      } catch (error) {
        toast.error("Failed to add car rental.");
      } finally {
        setIsAddingCar(false);
      }
      // }
    }
  };

  return (
    <>
      {isAddingCar && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">Create New Category</h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            <div className="title my-3 w-full col-span-6 md:col-span-6">
              <div className=" text-2xl text-gray-600 font-bold">
                Basic Info
              </div>
              <div className="mutted w-full text-xs text-mutted text-gray-400">
                Please fill the required fields
              </div>
            </div>

            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              <div className="col-span-12">
                <FormLabel className="text-gray-500" htmlFor="name">
                  Category Name
                </FormLabel>
                <FormInput
                  type="text"
                  id="name"
                  name="name"
                  value={meta?.name}
                  onChange={(e) => onMetaChange(e)}
                  disabled={mode === "view"}
                />
              </div>

              <div className="col-span-12 justify-self-end">
                <Button
                  className="bg-green-600 text-white"
                  onClick={onAddCategory}
                >
                  Add Car
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default CategoryForm;
