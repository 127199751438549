const filterBookingsHandler = (bookings, dateFrom, dateTo, month, year) => {
  return bookings.filter((b) => {
    const bookingDate = new Date(b?.booking_date);
    const bMonth = bookingDate.getMonth() + 1;
    const bYear = bookingDate.getFullYear();

    // Check if the bookingDate is within the specified date range
    const withinDateRange =
      (!dateFrom || bookingDate >= new Date(dateFrom)) &&
      (!dateTo || bookingDate <= new Date(dateTo));

    // Check if the bookingDate matches the specified month and year
    const correctMonth = !month || bMonth === month;
    const correctYear = !year || bYear === year;

    return withinDateRange && correctMonth && correctYear;
  });
};

export default filterBookingsHandler;
