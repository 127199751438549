import _ from "lodash";
import clsx from "clsx";
import { useEffect, useState } from "react";
import fakerData from "../../../utils/faker";
import Button from "../../../base-components/Button";
import Pagination from "../../../base-components/Pagination";
import { FormInput, FormSelect } from "../../../base-components/Form";
import Lucide from "../../../base-components/Lucide";
import Tippy from "../../../base-components/Tippy";
import Litepicker from "../../../base-components/Litepicker";
import ReportDonutChart from "../../../components/ReportDonutChart";
import ReportLineChart from "../../../components/ReportLineChart";
import ReportPieChart from "../../../components/ReportPieChart";
import ReportDonutChart1 from "../../../components/ReportDonutChart1";
import SimpleLineChart1 from "../../../components/SimpleLineChart1";
import LeafletMap from "../../../components/LeafletMap";
import { Menu } from "../../../base-components/Headless";
import Table from "../../../base-components/Table";
import useFetch from "../../../hooks/useFetch";
import { useDecodedToken } from "../../../hooks/useDecodedToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import calculateCurrentMonthTotals from "../../Finance/calculateCurrentMonthTotals";
import calculateStatusPercentages from "../../../helper/calculateStatusPercentages";
import { getMonthlyTotals } from "../../../helper/getMonthlyTotal";

function TransportDashoboard() {
  const [salesReportFilter, setSalesReportFilter] = useState<string>();

  const decodedToken = useDecodedToken();
  const [bookings, setBookings] = useState([]);

  const [transportType, setTransportType] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [statusPercentages, setStatusPercentages] = useState<any>({
    completed: 0,
    canceled: 0,
    pending: 0,
  });
  const [monthlyTotals, setMonthlyTotals] = useState([]);

  const navigate = useNavigate();

  // Filter bookings for the current month
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  useEffect(() => {
    if (!decodedToken) return console.log("decoded token is null");

    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/transport-type?transportUserId=${decodedToken.id}`
        );

        console.log("response", response);
        if (response.status === 200) {
          setTransportType(response.data.data);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log("error in dashboard transport type:", err);
      }
    };

    fetchApiData();
  }, [decodedToken]);

  useEffect(() => {
    if (!decodedToken) return console.log("decoded token is null");

    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/trip_bookings?userId=${decodedToken?.id}`
        );

        console.log(response.data);
        if (response.status === 200) {
          const allBookings = response.data.data;

          const date = new Date();

          const tPayments = calculateCurrentMonthTotals(allBookings, date);
          const _monthlyTotals = getMonthlyTotals(allBookings);
          setMonthlyTotals(_monthlyTotals);
          const bookingStatusPercentage =
            calculateStatusPercentages(allBookings);
          console.log("bookingStatusPercentage", bookingStatusPercentage);
          setStatusPercentages(bookingStatusPercentage);

          console.log("tPayments", tPayments);
          setTotalPayments(tPayments?.totalPrice);

          const filteredBookings = allBookings.filter((b) => {
            const bookingDate = new Date(b.booking_date);

            return (
              bookingDate.getMonth().toString() === currentMonth.toString() &&
              bookingDate.getFullYear().toString() === currentYear.toString()
            );
          });
          console.log("bookings in this month", filteredBookings);
          setBookings(filteredBookings);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, [decodedToken]);

  const currentDate = new Date().toISOString().slice(0, 10);

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 mt-8">
            <div className="flex items-center h-10 intro-y">
              <h2 className="mr-5 text-lg font-medium truncate">
                General Report
              </h2>
              <a href="" className="flex items-center ml-auto text-primary">
                <Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /> Reload
                Data
              </a>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div
                className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
                onClick={() => {
                  navigate(`/transport_bookings?date=${currentDate}`, {
                    state: { bookings },
                  });
                }}
              >
                <div
                  className={clsx([
                    "relative zoom-in",
                    "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                  ])}
                >
                  <div className="p-5 box">
                    <div className="flex">
                      <Lucide
                        icon="ShoppingCart"
                        className="w-[28px] h-[28px] text-primary"
                      />
                    </div>
                    <div className="mt-6 text-3xl font-medium leading-8">
                      {bookings?.length > 0 ? bookings.length : 0}
                    </div>
                    <div className="mt-1 text-base text-slate-500">
                      This Month Bookings
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
                onClick={() => navigate("/transport-type")}
              >
                <div
                  className={clsx([
                    "relative zoom-in",
                    "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                  ])}
                >
                  <div className="p-5 box">
                    <div className="flex">
                      <Lucide
                        icon="CreditCard"
                        className="w-[28px] h-[28px] text-pending"
                      />
                    </div>
                    <div className="mt-6 text-3xl font-medium leading-8">
                      {transportType?.length > 0 ? transportType.length : 0}
                    </div>
                    <div className="mt-1 text-base text-slate-500">
                      Transport Type
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
                onClick={() => navigate(`/payments?date=${currentDate}`)}
              >
                <div
                  className={clsx([
                    "relative zoom-in",
                    "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                  ])}
                >
                  <div className="p-5 box">
                    <div className="flex">
                      <Lucide
                        icon="Monitor"
                        className="w-[28px] h-[28px] text-warning"
                      />
                    </div>
                    <div className="mt-6 text-3xl font-medium leading-8">
                      {totalPayments}
                    </div>
                    <div className="mt-1 text-base text-slate-500">
                      This Month Total Payment
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 mt-8 lg:col-span-9">
            <div className="items-center block h-10 intro-y sm:flex">
              <h2 className="mr-5 text-lg font-medium truncate">
                Payment Report
              </h2>
            </div>
            <div className="p-5 mt-12 intro-y box sm:mt-5">
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="flex">
                  <div>
                    <div className="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl">
                      {monthlyTotals[currentMonth]}
                    </div>
                    <div className="mt-0.5 text-slate-500">This Month</div>
                  </div>
                  <div className="w-px h-12 mx-4 border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"></div>
                  <div>
                    <div className="text-lg font-medium text-slate-500 xl:text-xl">
                      {monthlyTotals[currentMonth - 1]}
                    </div>
                    <div className="mt-0.5 text-slate-500">Last Month</div>
                  </div>
                </div>
              </div>
              <div
                className={clsx([
                  "relative",
                  "before:content-[''] before:block before:absolute before:w-16 before:left-0 before:top-0 before:bottom-0 before:ml-10 before:mb-7 before:bg-gradient-to-r before:from-white before:via-white/80 before:to-transparent before:dark:from-darkmode-600",
                  "after:content-[''] after:block after:absolute after:w-16 after:right-0 after:top-0 after:bottom-0 after:mb-7 after:bg-gradient-to-l after:from-white after:via-white/80 after:to-transparent after:dark:from-darkmode-600",
                ])}
              >
                {monthlyTotals.length > 0 && (
                  <ReportLineChart
                    height={275}
                    total={monthlyTotals}
                    className="mt-6 -mb-6"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-span-12 mt-8 sm:col-span-6 lg:col-span-3">
            <div className="flex items-center h-10 intro-y">
              <h2 className="mr-5 text-lg font-medium truncate">Bookings</h2>
              {/* <a href="" className="ml-auto truncate text-primary">
                Show More
              </a> */}
            </div>
            <div className="p-5 mt-5 intro-y box">
              <div className="mt-3">
                <ReportPieChart
                  height={213}
                  chartData={[
                    statusPercentages?.pending,
                    statusPercentages?.canceled,
                    statusPercentages?.booked,
                  ]}
                />
              </div>
              <div className="mx-auto mt-8 w-52 sm:w-auto">
                <div className="flex items-center">
                  <div className="w-2 h-2 mr-3 rounded-full bg-primary"></div>
                  <span className="truncate">Booked</span>
                  <span className="ml-auto font-medium">
                    {" "}
                    {statusPercentages?.booked}%
                  </span>
                </div>
                <div className="flex items-center mt-4">
                  <div className="w-2 h-2 mr-3 rounded-full bg-pending"></div>
                  <span className="truncate">Pending</span>
                  <span className="ml-auto font-medium">
                    {statusPercentages?.pending}%
                  </span>
                </div>
                <div className="flex items-center mt-4">
                  <div className="w-2 h-2 mr-3 rounded-full bg-warning"></div>
                  <span className="truncate">Cancelled</span>
                  <span className="ml-auto font-medium">
                    {statusPercentages?.canceled}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransportDashoboard;
