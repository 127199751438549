import { ChangeEvent, useState, useEffect } from "react";
import { PreviewComponent } from "../../base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "../../base-components/Form";
import toast from "react-hot-toast";
import Button from "../../base-components/Button";
import Loading from "../../components/Loading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import AdditionalPrices, {
  addAdditionalPrice,
} from "./Component/AdditionalPrices";

const EditTransport = () => {
  const navigate = useNavigate();
  const [isAddingTransportType, setIsAddingTransportType] =
    useState<boolean>(false);

  const location = useLocation();
  const { state, pathname } = location;
  const isViewPage = pathname.includes("view-transport");

  const [loading, setLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState(state);

  const onMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const onUpdateTransportType = () => {

    // Validating fields
    if (!meta.label) {
      toast.error("Label is required");
    } else if (!meta.seats) {
      toast.error("Seats must be greater than zero");
    } else if (!meta.description) {
      toast.error("Description is required");
    } else if (!meta.price_per_km) {
      toast.error("Price Per Km is required");
    } else if (!meta.people) {
      toast.error("People must be greater than zero");
    } else if (!meta.bags) {
      toast.error("Luggage must be greater than zero");
    } else if (!meta.doors) {
      toast.error("Doors must be greater than zero");
    } else {
      setIsAddingTransportType(true);
      meta.value = meta.label;

      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/transport-type/${state?.id}`,
          meta
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Transport type updated successfully");
            setIsAddingTransportType(false);
            navigate("/transport-type");
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            toast.error("Something went wrong! Please try again later.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    }
  };


  return (
    <>
      {loading == true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">
            {/* {isViewPage ? meta.label : "Update transport"} */}
            {meta.label}
          </h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            <div className="title my-3 w-full col-span-6 md:col-span-6">
              <div className=" text-2xl text-gray-600 font-bold">
                Basic Info
              </div>
              <div className="mutted w-full text-xs text-mutted text-gray-400">
                Please fill the required fields
              </div>
            </div>
            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              {/* <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="hotelName">
                  Label
                </FormLabel>
                <FormInput
                  type="text"
                  id="label"
                  name="label"
                  value={meta.label}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="add label name"
                  disabled={isViewPage}
                />
              </div> */}
              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="bags">
                  Luggage
                </FormLabel>
                <FormInput
                  type="number"
                  min={0}
                  id="bags"
                  name="bags"
                  value={meta.bags}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="Luggage capacity"
                  disabled={isViewPage}
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="doors">
                  Doors
                </FormLabel>
                <FormInput
                  type="number"
                  id="doors"
                  name="doors"
                  value={meta.doors}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="Doors"
                  disabled={isViewPage}
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="people">
                  People
                </FormLabel>
                <FormInput
                  type="number"
                  id="people"
                  name="people"
                  value={meta.people}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="People"
                  disabled={isViewPage}
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="price_per_km">
                  Price/km
                </FormLabel>
                <FormInput
                  type="number"
                  id="price_per_km"
                  name="price_per_km"
                  value={meta.price_per_km}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="Price per kilometer"
                  disabled={isViewPage}
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="seats">
                  Seats
                </FormLabel>
                <FormInput
                  type="number"
                  id="seats"
                  name="seats"
                  value={meta.seats}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="seats"
                  disabled={isViewPage}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="ac">
                  AC
                </FormLabel>
                <FormSelect
                  id="ac"
                  name="ac"
                  value={meta.ac ? "true" : "false"}
                  onChange={(e) => onMetaChange(e)}
                  disabled={isViewPage}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </FormSelect>
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="auto">
                  Auto
                </FormLabel>
                <FormSelect
                  id="auto"
                  name="auto"
                  value={meta.auto ? "true" : "false"}
                  onChange={(e) => onMetaChange(e)}
                  disabled={isViewPage}
                >
                  <option value="false">Manual</option>
                  <option value="true">Auto</option>
                </FormSelect>
              </div>

              <div className="col-span-6 mt-3">
                <FormLabel className="text-gray-500" htmlFor="description">
                  Description
                </FormLabel>

                <FormTextarea
                  id="description"
                  name="description"
                  rows={3}
                  value={meta.description}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="Description"
                  disabled={isViewPage}
                />
              </div>
            </div>

            <div className="col-span-6 mt-3">
              <FormLabel className="text-gray-500">Additional Prices</FormLabel>
              <AdditionalPrices
                meta={meta}
                setMeta={setMeta}
                isViewPage={isViewPage}
              />
              {!isViewPage ? (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => addAdditionalPrice(meta, setMeta)}
                  className="text-blue-500 w-full mt-5 "
                >
                  Add Additional Price
                </p>
              ) : (
                ""
              )}
            </div>

            {!isViewPage ? (
              <div className="col-span-6 justify-self-end">
                <Button
                  className="bg-green-600 text-white"
                  onClick={onUpdateTransportType}
                >
                  Update
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default EditTransport;
