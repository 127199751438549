import React from "react";

function dateFormat(dateString: any) {
  const dateTime = new Date(dateString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];  
  return `${
    months[dateTime.getMonth()]
  }/${dateTime.getDate()}/${dateTime.getFullYear()}`;
}

function timeFormat(dateString: any) {
  // Convert the timestamp to a Date object
  const date = new Date(parseInt(dateString));

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Format the date and time components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Return the formatted date and time string
  return `${hours}:${minutes}`;
}
export { dateFormat, timeFormat };
