import { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  FormInput,
  FormLabel,
  FormTextarea,
} from "../../../base-components/Form";

import { PreviewComponent } from "../../../base-components/PreviewComponent";


import CustomFormInput from "../../../custom-components/CustomFormInput";
import Loading from "../../../components/Loading";
import Table from "../../../base-components/Table";
import { dateFormat } from "../../../helper/dateFormat";

const TransportBookingDetails = () => {
  const location = useLocation();
  const [bookingDetails] = useState(location.state);
  const [loading, setLoading] = useState<boolean>(false);
  const { transport_type, pick_up_location, drop_off_location } =
    bookingDetails;
  const { additional_price } = transport_type;

  const totalAdditionalPrice = additional_price.reduce((total, item) => {
    
    return total + parseInt(item.price);
  }, 0);

  console.log('bookingDetails :', bookingDetails);

  return (
    <>
      {loading === true && <Loading />}

      {//@ts-ignore  

        <PreviewComponent className="intro-y box">

          <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
            <h1 className="text-2xl font-bold my-4">Complete Booking Details</h1>
          </div>
          <div className={"flex flex-col items-center py-5 px-8"}>

            <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              {//@ts-ignore  

                <CustomFormInput
                  label="Booking Id"
                  name="booking_id"
                  type="text"
                  value={bookingDetails.booking_id}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Booking Date"
                  name="booking_date"
                  type="text"
                  value={dateFormat(bookingDetails.booking_date)}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Paid Status"
                  name="paid_status"
                  type="text"
                  value={bookingDetails?.paid_status}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Transport User Id"
                  name="transport_user_id"
                  type="text"
                  value={bookingDetails?.transport_user_id}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Customer Email"
                  name="email"
                  type="text"
                  value={bookingDetails?.email}
                  disabled={true}
                />
              }
            </div>

            <div className="title my-3 w-full col-span-6">
              <div className=" text-2xl text-gray-600 font-bold mt-4">
                Trip Details
              </div>
            </div>

            <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="hotelName">
                  Label
                </FormLabel>
                <FormInput
                  type="text"
                  id="label"
                  name="label"
                  value={transport_type.label}
                  disabled={true}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="bags">
                  Luggage
                </FormLabel>
                <FormInput
                  type="number"
                  min={0}
                  id="bags"
                  name="bags"
                  value={transport_type.bags}
                  disabled={true}
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="doors">
                  Doors
                </FormLabel>
                <FormInput
                  type="number"
                  id="doors"
                  name="doors"
                  value={transport_type.doors}
                  disabled={true}
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="people">
                  People
                </FormLabel>
                <FormInput
                  type="number"
                  id="people"
                  name="people"
                  value={transport_type.people}
                  disabled={true}
                />
              </div>

              {/* <div className="col-span-4 md:col-span-2">
              <FormLabel className="text-gray-500" htmlFor="price_per_km">
                Price/km
              </FormLabel>
              <FormInput
                type="number"
                id="price_per_km"
                name="price_per_km"
                value={transport_type.price_per_km}
                disabled={true}
              />
            </div> */}

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="seats">
                  Seats
                </FormLabel>
                <FormInput
                  type="number"
                  id="seats"
                  name="seats"
                  value={transport_type.seats}
                  disabled={true}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="ac">
                  AC
                </FormLabel>
                <FormInput
                  id="ac"
                  name="ac"
                  value={transport_type.ac ? "true" : "false"}
                  disabled={true}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="auto">
                  Auto
                </FormLabel>
                <FormInput
                  id="auto"
                  name="auto"
                  value={transport_type.auto ? "auto" : "manual"}
                  disabled={true}
                />
              </div>

              {//@ts-ignore  
                <CustomFormInput
                  label="Pickup Location"
                  name="pickup_location"
                  type="text"
                  value={pick_up_location?.place}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Drop off Location"
                  name="dropoff_location"
                  type="text"
                  value={drop_off_location?.place}
                  disabled={true}
                />
              }
              {//@ts-ignore  
                <CustomFormInput
                  label="Trip Date"
                  name="trip_date"
                  type="text"
                  value={dateFormat(bookingDetails?.trip_date)}
                  disabled={true}
                />
              }

              <div className="col-span-6 mt-3">
                <FormLabel className="text-gray-500" htmlFor="description">
                  Description
                </FormLabel>

                <FormTextarea
                  id="description"
                  name="description"
                  rows={3}
                  value={transport_type.description}
                  disabled={true}
                />

                {/* Additional Prices  */}
                {/* <div className="title mt-3 w-full col-span-6">
                <div className=" text-2xl text-gray-600 font-bold mt-4">
                  Additional Price
                </div>
              </div> */}
              <div className="col-span-12 md:col-span-12">
                <div className="overflow-x-auto">
                  <Table hover className="w-full mt-5">
                    <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
                      <Table.Tr>
                        <Table.Th className="whitespace-nowrap">Price per KM</Table.Th>
                        <Table.Th className="whitespace-nowrap">Admin Commission</Table.Th>
                        <Table.Th className="whitespace-nowrap">Additional Price</Table.Th>
                        <Table.Th className="whitespace-nowrap">Total Amount</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                     
                          <Table.Tr className="text-center text-xs">
                            <Table.Td>{transport_type?.price_per_km}</Table.Td>
                            <Table.Td>{bookingDetails?.our_commission}</Table.Td>
                            <Table.Td>{totalAdditionalPrice} {additional_price[0].label}</Table.Td>
                            <Table.Td>{bookingDetails?.total_price}</Table.Td>
                          </Table.Tr>
                        {/* );
                      })} */}
                      </Table.Tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PreviewComponent>
      }
    </>
  );
};

export default TransportBookingDetails;
