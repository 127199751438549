import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

export const useDecodedToken = () => {
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    let AuthToken = localStorage.getItem("doyoofToken");
    const decToken = AuthToken ? jwtDecode(AuthToken?.split(" ")[1] || "") : "";
    // console.log('decToken',decToken)
    setDecodedToken(decToken);
  }, []);

  return decodedToken;
};
