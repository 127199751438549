import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";

const DriversList = ({
  bookingsData,
  setBookingsData,
  onSelectedDriverId,
  triggerRerender,
  assignRowId,
  setIsModalVisible
}) => {
  // const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [drivers] = useFetch("drivers");

  // useEffect(() => {
  //   setSelectedDriverId(null);
  // }, [triggerRerender]);

  const assignDriver = async (email, booking_id, driver_id, code) => {
    console.log(email, booking_id, driver_id, code)
    console.log("EMAIL: ", email);
    // setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/assign-driver`,
        { id: booking_id, email, driver_id, code }
      );
      console.log("response",response)
      if (response.status === 200) {
        toast.success("Driver assigned successfully");
      } else {
        throw new Error("Assignment failed");
      }
    } catch (error) {
      console.error("Assignment error:", error);
      toast.error("Failed to assign driver.");
    }
    // setLoading(false);
  };

  const onAssignDriverClick = async (id) => {
    if (id && assignRowId) {
      const uniqueCode = Math.random().toString(36).substr(2, 7).toUpperCase();

      const customerEmail = bookingsData.find(
        (booking) => booking.id === assignRowId
      ).email;
      console.log("customerEmail", customerEmail);
      await assignDriver(customerEmail, assignRowId, id, uniqueCode);

      setBookingsData(
        bookingsData.map((booking) =>
          booking.id === assignRowId
            ? { ...booking, driver_id: id, code: uniqueCode }
            : booking
        )
      );

      // // Resetting state after updating
      // setAssignRowId(null);
      // setSelectedDriverId(null);
    }
    setIsModalVisible(false);
  };

  return (
    <div className="space-y-4">
      {drivers &&
        drivers?.map((driver) => (
          <div
            key={driver.id}
            className="flex items-center justify-between bg-white shadow-md rounded-lg p-4"
          >
            <div className="flex items-center">
              <img
                src={driver.image}
                alt={driver.name}
                className="w-12 h-12 rounded-full mr-4 border border-gray-300"
              />
              <div>
                <h3 className="text-lg font-semibold">{driver.name}</h3>
                <p className="text-sm text-gray-500">{driver.phonenumber}</p>
              </div>
            </div>
            {/* {selectedDriverId === driver.id ? (
              <span className="text-green-500 font-semibold px-4 py-2 rounded-lg bg-gray-200">
                Assigned
              </span>
            ) : ( */}
            <Button
              onClick={() => onAssignDriverClick(driver.id)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
            >
              Assign
            </Button>
            {/* )} */}
          </div>
        ))}
    </div>
  );
};

export default DriversList;
