const mergeBookingsBasedOnBookingId = (bookings: any) => {
  let mergedBookings: any = {};

  // Iterate through each booking
  bookings.forEach((booking: any) => {
    let bookingId = booking.booking_id;

    if (mergedBookings[bookingId]) {
      // If the booking ID already exists, sum up the total price
      mergedBookings[bookingId].total_price += booking.total_price;
    } else {
      // If the booking ID does not exist, add the booking to the object
      mergedBookings[bookingId] = { ...booking };
    }
  });

  // Convert the object back to an array
  let mergedBookingsArray = Object.values(mergedBookings);

  return mergedBookingsArray;
};

export default mergeBookingsBasedOnBookingId;
