import { NavLink } from "react-router-dom";
import Table from "../../base-components/Table";
import { dateFormat } from "../../helper/dateFormat";
import calculateAdminCommission from "../../helper/calculateCommission";
import Loading from "../../components/Loading";

const PaymentTable = ({ bookings, renderBookingList, finalTotals }: any) => {
  let balance = 0;
  let final_totalPrice = 0;
  let final_our_commission = 0;
  let final_debit = 0;
  let final_credit = 0;

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
          <Table.Tr>
            <Table.Th className="whitespace-nowrap">Date</Table.Th>
            <Table.Th className="whitespace-nowrap">Booking Id</Table.Th>
            <Table.Th className="whitespace-nowrap">Total Amount</Table.Th>
            <Table.Th className="whitespace-nowrap">Commisison Paid</Table.Th>
            <Table.Th className="whitespace-nowrap">Debit</Table.Th>
            <Table.Th className="whitespace-nowrap">Credit</Table.Th>
            <Table.Th className="whitespace-nowrap">Balance</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {bookings?.length === 0 ? (
            <Table.Tr>
              <Table.Td colSpan={6} className="text-center">
                Currently No Payments Available
              </Table.Td>
            </Table.Tr>
          ) : bookings ? (
            bookings.map((booking, index) => {
              const totalWithAdditionalPrice = booking?.total_price;

              const admin_comission = calculateAdminCommission(
                totalWithAdditionalPrice,
                parseFloat(booking?.our_commission || booking?.admin_comission)
              );
              console.log('admin' , admin_comission)
              const credit = totalWithAdditionalPrice - admin_comission;
              balance += credit;
              // Update final totals
              final_totalPrice += totalWithAdditionalPrice;
              final_our_commission += admin_comission;
              final_debit += admin_comission;
              final_credit += credit;
              return renderBookingList(booking, index, balance);
            })
          ) : (
            <Loading />
          )}
        </Table.Tbody>
        <Table.Tfoot>
          <Table.Tr className="text-center bg-primary rounded-2xl text-gray-100 font-bold">
            <Table.Td>Total</Table.Td>
            <Table.Td></Table.Td>
            <Table.Td>{final_totalPrice.toFixed(2)}</Table.Td>
            <Table.Td>{final_our_commission.toFixed(2)}</Table.Td>
            <Table.Td>{final_debit.toFixed(2)}</Table.Td>
            <Table.Td>{final_credit.toFixed(2)}</Table.Td>
            <Table.Td>{balance.toFixed(2)}</Table.Td>
          </Table.Tr>
        </Table.Tfoot>
      </Table>
    </div>
  );
};

export default PaymentTable;
