import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";

import { NavLink } from "react-router-dom";
import Table from "../../base-components/Table";
import { useEffect, useState } from "react";
import { Dialog } from "../../base-components/Headless";
import Lucide from "../../base-components/Lucide";
import Button from "../../base-components/Button";
import axios from "axios";
import AddButton from "../../custom-components/AddButton";
import MainTitle from "../../custom-components/MainTitle";
import useFetch from "../../hooks/useFetch";

const CarRental = () => {
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);

  const [carRental, setCarRental] = useState<any>([]);
  const [reservedCars] = useFetch("car-rental/reserved");
  const [seasonalPrices] = useFetch("car-rental/seasonal-price");


  useEffect(() => {
   
    const fetchApiData = async () => {
      try {
       
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/car-rental`
        );

        console.log(response);
        if (response.status === 200) {
          setCarRental(response.data.data);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, []);
  async function itemDeleteHandler() {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/car-rental/${deleteItemId}`
    );
    if (response.status === 200) {
      const filter = carRental.filter((item: any) => item.id !== deleteItemId);
      setCarRental(filter);
    }
  }

  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between md:items-center my-4">
          <MainTitle title="Car Rental Information" />
          <AddButton redirectTo="/add-car" btnTitle="Add Car" />
        </div>

        <div className="overflow-x-auto">
          <Table hover>
            <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
              <Table.Tr>
                <Table.Th className="whitespace-nowrap">#</Table.Th>
                <Table.Th className="whitespace-nowrap">Car Name</Table.Th>
                <Table.Th className="whitespace-nowrap">Car Compnay</Table.Th>
                <Table.Th className="whitespace-nowrap">Car Category</Table.Th>

                <Table.Th className="whitespace-nowrap">Model</Table.Th>
                <Table.Th className="whitespace-nowrap">Color</Table.Th>
                <Table.Th className="whitespace-nowrap">Details</Table.Th>
                <Table.Th className="whitespace-nowrap">Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {carRental && carRental.length === 0 ? (
                <Table.Tr>
                  <Table.Td colSpan={8} className="text-center">
                    Currently no Transport car added
                  </Table.Td>
                </Table.Tr>
              ) : (
                carRental &&
                carRental.map((car: any, index: any) => {
                  return (
                    <Table.Tr
                      key={carRental?.id}
                      className="text-center text-xs"
                    >
                      <Table.Td>{index + 1}</Table.Td>
                      <Table.Td>{car?.car_name}</Table.Td>
                      <Table.Td>{car?.car_company}</Table.Td>
                      <Table.Td>{car?.car_category}</Table.Td>
                      <Table.Td>{car?.model}</Table.Td>
                      <Table.Td>{car?.color}</Table.Td>

                      <Table.Td>
                        <NavLink
                          to={`/view-car/${car?.id}`}
                          state={{ car, reservedCars, seasonalPrices }}
                        >
                          <button className="bg-success p-2 rounded-md text-white flex items-center">
                            <i className="fa-solid fa-eye pe-1"></i>
                            View
                          </button>
                        </NavLink>
                      </Table.Td>

                      <Table.Td>
                        <div className="flex items-center justify-center">
                          <NavLink
                            to={`/edit-car/${car?.id}`}
                            state={{ car, reservedCars, seasonalPrices }}
                          >
                            <button className="flex items-center mr-3">
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                          </NavLink>

                          <button
                            onClick={() => {
                              console.log("Hello");
                              setDeleteItemId(car?.id);
                              setIsModelOpen(true);
                            }}
                            className="flex items-center text-danger"
                            disabled={isDeleting}
                            // disabled={true}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })
              )}
            </Table.Tbody>
          </Table>
        </div>
      </div>
      <Dialog
        open={isModelOpen}
        onClose={() => {
          setIsModelOpen(false);
        }}
        className={"pt-4"}
      >
        <Dialog.Panel className={"top-12"}>
          <div className="p-5 text-center">
            <Lucide
              icon="XCircle"
              className="w-16 h-16 mx-auto mt-3 text-danger"
            />
            <div className="mt-5 text-3xl">Are you sure?</div>
            <div className="mt-2 text-slate-500">
              Do you really want to delete these records? <br />
              This process cannot be undone.
            </div>
          </div>
          <div className="px-5 pb-8 text-center">
            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => {
                setIsModelOpen(false);
                console.log("Cancel");
              }}
              className="w-24 mr-1"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="danger"
              className="w-24"
              onClick={() => {
                if (deleteItemId) {
                  itemDeleteHandler();
                }
                setIsModelOpen(false);
                console.log("Delete");
              }}
            >
              Delete
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default CarRental;
