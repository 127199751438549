// @ts-nocheck
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { PreviewComponent } from "../../base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "../../base-components/Form";
import Button from "../../base-components/Button";
import Loading from "../../components/Loading";
import AdditionalPrices, {
  addAdditionalPrice,
} from "./Component/AdditionalPrices";
import { useDecodedToken } from "../../hooks/useDecodedToken";

const CreateTransportType = () => {
  const navigate = useNavigate();
  const [isAddingTransportType, setIsAddingTransportType] =
    useState<boolean>(false);

  const decodedToken = useDecodedToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState({
    label: "",
    value: "",
    price_per_km: 0,
    people: 0,
    bags: 0,
    seats: 0,
    ac: false,
    auto: false,
    doors: 0,
    description: "",
    additional_price: [],
  });

  const onCreateMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const onAddTransportType = () => {
    if (!decodedToken?.id) return console.log("decoded token is null");
    if (!meta.label) {
      toast.error("Label is required");
    } else if (!meta.seats) {
      toast.error("Seats must be greater than zero");
    } else if (!meta.description) {
      toast.error("Description is required");
    } else if (!meta.price_per_km) {
      toast.error("Price Per Km is required");
    } else if (!meta.people) {
      toast.error("People must be greater than zero");
    } else if (!meta.bags) {
      toast.error("Luggage must be greater than zero");
    } else if (!meta.doors) {
      toast.error("Doors must be greater than zero");
    } else {
      setIsAddingTransportType(true);
      meta.value = meta.label;

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/transport-type/${decodedToken?.id}`,
          meta
        )
        .then((response) => {
          if (response.status === 201) {
            toast.success("Transport type created successfully");
            setIsAddingTransportType(false);
            navigate("/transport-type");
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            toast.error("Something went wrong! Please try again later.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    }
  };

  return (
    <>
      {loading == true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">Create New transport</h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            <div className="title my-3 w-full col-span-6 md:col-span-6">
              <div className=" text-2xl text-gray-600 font-bold">
                Basic Info
              </div>
              <div className="mutted w-full text-xs text-mutted text-gray-400">
                Please fill the required fields
              </div>
            </div>
            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="hotelName">
                  Label
                </FormLabel>
                <FormInput
                  type="text"
                  id="label"
                  name="label"
                  value={meta.label}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="add label name"
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="bags">
                  Luggage
                </FormLabel>
                <FormInput
                  type="number"
                  min={0}
                  id="bags"
                  name="bags"
                  value={meta.bags}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="Luggage capacity"
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="doors">
                  Doors
                </FormLabel>
                <FormInput
                  type="number"
                  id="doors"
                  name="doors"
                  value={meta.doors}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="Doors"
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="people">
                  People
                </FormLabel>
                <FormInput
                  type="number"
                  id="people"
                  name="people"
                  value={meta.people}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="People"
                />
              </div>

              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="price_per_km">
                  Price/km
                </FormLabel>
                <FormInput
                  type="number"
                  id="price_per_km"
                  name="price_per_km"
                  value={meta.price_per_km}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="Price per kilometer"
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="seats">
                  Seats
                </FormLabel>
                <FormInput
                  type="number"
                  id="seats"
                  name="seats"
                  value={meta.seats}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="seats"
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="ac">
                  AC
                </FormLabel>
                <FormSelect
                  id="ac"
                  name="ac"
                  value={meta.ac ? "true" : "false"}
                  onChange={(e) => onCreateMetaChange(e)}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </FormSelect>
              </div>

              <div className="col-span-6 md:col-span-3">
                <FormLabel className="text-gray-500" htmlFor="auto">
                  Auto
                </FormLabel>
                <FormSelect
                  id="auto"
                  name="auto"
                  value={meta.auto ? "true" : "false"}
                  onChange={(e) => onCreateMetaChange(e)}
                >
                  <option value="false">Manual</option>
                  <option value="true">Auto</option>
                </FormSelect>
              </div>

              <div className="col-span-6 mt-3">
                <FormLabel className="text-gray-500" htmlFor="description">
                  Description
                </FormLabel>

                <FormTextarea
                  id="description"
                  name="description"
                  rows={3}
                  value={meta.description}
                  onChange={(e) => onCreateMetaChange(e)}
                  placeholder="Description"
                />
              </div>
            </div>
            <div className="col-span-6 mt-3">
              <FormLabel className="text-gray-500">Additional Prices</FormLabel>
              <AdditionalPrices meta={meta} setMeta={setMeta} />
              <p
                style={{ cursor: "pointer" }}
                onClick={() => addAdditionalPrice(meta, setMeta)}
                className="text-blue-500 w-full mt-5 "
              >
                Add Additional Price
              </p>
              {/* <Button
                onClick={addAdditionalPrice}
                className="bg-blue-500 text-white"
              >
                Add Additional Price
              </Button> */}
            </div>

            <div className="col-span-6 justify-self-end">
              <Button
                className="bg-green-600 text-white"
                onClick={onAddTransportType}
              >
                Add Transport type
              </Button>
            </div>
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default CreateTransportType;
