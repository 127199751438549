import { ChangeEvent, useEffect, useState } from "react";

import Loading from "../../../components/Loading";
import { NavLink, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import { dateFormat } from "../../../helper/dateFormat";
import Table from "../../../base-components/Table";
import { calculateNightsBetweenDates } from "../../../helper/calculateNights";
import calculateAdminCommission from "../../../helper/calculateCommission";
import filterBookingsHandler from "../../../helper/filterBookings";
import SearchFilters from "../../../custom-components/SearchFilters";
import Heading from "../../../custom-components/Finance/Heading";
import { useDecodedToken } from "../../../hooks/useDecodedToken";

function Payments() {
  const [loading, setLoading] = useState<boolean>(false);
  const [sortByValue, setSortByValue] = useState([]);
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();
  const [bookings, setBookings] = useState<any>();
  const [filteredBookings, setFilteredBookings] = useState<any>();

  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);

  // Get a specific query parameter
  const date = params.get("date");

  console.log("location", location);
  console.log("queryString", queryString);
  console.log("params", params);
  console.log("date", date);
  useEffect(() => {
    if (!date || !bookings) return;

    const d = new Date(date);

    const m = d.getMonth() + 1;
    console.log("m", m);
    setMonth(m);

    const filteredBookings = filterBookingsHandler(
      bookings,
      dateFrom,
      dateTo,
      m,
      year
    );
    setFilteredBookings(filteredBookings);
  }, [date, bookings]);

  let final_totalPrice = 0;
  let final_our_commission = 0;
  let final_debit = 0;
  let final_credit = 0;

  const decodedToken = useDecodedToken();

  useEffect(() => {
    if (!decodedToken) return console.log("decoded token is null");

    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bookings/car-rental/${decodedToken?.id}`
        );

        console.log(response.data);
        if (response.status === 200) {
          console.log("car rental bookings : ", response.data.data);
          setFilteredBookings(response.data?.data);
          setBookings(response.data?.data);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, [decodedToken]);

  const SearchFilterHandler = async () => {
    const filteredBookings = filterBookingsHandler(
      bookings,
      dateFrom,
      dateTo,
      month,
      year
    );
    setFilteredBookings(filteredBookings);
  };
  const ResetFilter = () => {
    setDateFrom("");
    setDateTo("");
    setMonth("");
    setYear("");
  };
  let total_balance = 0;

  //paginnation
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = sortByValue?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  let balance = 0;

  const renderBookingList = (booking, index) => {
    const totalWithAdditionalPrice = booking?.total_price;

    const admin_comission = calculateAdminCommission(
      parseFloat(booking?.admin_comission),
      totalWithAdditionalPrice
    );
    const credit = totalWithAdditionalPrice - admin_comission;
    balance += credit;
    // Update final totals
    final_totalPrice += totalWithAdditionalPrice;
    final_our_commission += admin_comission;
    final_debit += admin_comission;
    final_credit += credit;
    return (
      <Table.Tr key={booking.id} className="text-center text-xs">
        <Table.Td>{dateFormat(booking.booking_date)}</Table.Td>
        <Table.Td style={{ color: "blue" }}>
          <NavLink
            //   target="_blank"
            //   rel="noopener noreferrer"
            to={`/view-car_rental-booking-details/${booking.booking_id}`}
            state={booking}
          >
            {booking.booking_id}{" "}
          </NavLink>
        </Table.Td>

        <Table.Td>{totalWithAdditionalPrice}</Table.Td>

        <Table.Td>{admin_comission}</Table.Td>
        <Table.Td>{admin_comission}</Table.Td>
        <Table.Td>{totalWithAdditionalPrice - admin_comission}</Table.Td>

        <Table.Td>{balance.toFixed(2)}</Table.Td>
      </Table.Tr>
    );
  };
  return (
    <>
      {loading && <Loading />}
      <div className="container mx-auto">
        <Heading heading="Total Payment Report" />

        <SearchFilters
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
          ResetFilter={ResetFilter}
          onSearchFilter={SearchFilterHandler}
        />

        <div className="overflow-x-auto">
          <Table>
            <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
              <Table.Tr>
                <Table.Th className="whitespace-nowrap">Date</Table.Th>
                <Table.Th className="whitespace-nowrap">Booking Id</Table.Th>

                <Table.Th className="whitespace-nowrap">Total Amount</Table.Th>
                <Table.Th className="whitespace-nowrap">
                  Commisison Paid
                </Table.Th>

                <Table.Th className="whitespace-nowrap">Debit</Table.Th>
                <Table.Th className="whitespace-nowrap">Credit</Table.Th>
                <Table.Th className="whitespace-nowrap">Balance</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {filteredBookings?.length === 0 ? (
                <Table.Tr>
                  <Table.Td colSpan={6} className="text-center">
                    Currently No Payments Available
                  </Table.Td>
                </Table.Tr>
              ) : filteredBookings ? (
                filteredBookings.map((booking, index) =>
                  renderBookingList(booking, index)
                )
              ) : (
                <Loading />
              )}
            </Table.Tbody>
            <Table.Tfoot>
              <Table.Tr className="text-center bg-primary rounded-2xl text-gray-100 font-bold">
                <Table.Td>Total</Table.Td>
                <Table.Td></Table.Td>

                <Table.Td>{final_totalPrice.toFixed(2)}</Table.Td>
                <Table.Td>{final_our_commission.toFixed(2)}</Table.Td>
                {/* <Table.Td></Table.Td>/ */}
                <Table.Td>{final_debit.toFixed(2)}</Table.Td>
                <Table.Td>{final_credit.toFixed(2)}</Table.Td>
                <Table.Td>{balance.toFixed(2)}</Table.Td>
              </Table.Tr>
            </Table.Tfoot>
          </Table>
          {/* {(currentItems?.length < ITEMS_PER_PAGE || currentPage >= 1) && (
            <Pagination
              currentPage={currentPage}
              totalItems={paymentData.length}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )} */}
        </div>
      </div>
    </>
  );
}

export default Payments;
