import { FormInput, FormLabel } from "../../base-components/Form";
import Button from "../../base-components/Button";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/Auth";
import logoUrl from "../../assets/images/logo.png";
import { ChangeEvent, useEffect, useState } from "react";
import Loader from "../../base-components/Loader";
import toast from "react-hot-toast";
import axios, { AxiosError, AxiosResponse } from "axios";
import Loading from "../../components/Loading";

interface SignUpMeta {
  c_email: string;
  email: string;
  registeration_number: number;
  phonenumber: number;
  website_link: string;
  password: string;
  type: string;
  commission: number;
  id_card: string;
  business_license: string;
  username: string;
}

interface ErrorResponseData {
  error?: string;
}

function Main() {
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [idCard, setIDCard] = useState<string | null>(null);
  const [businessLicense, setBusinessLicense] = useState<string | null>(null);
  const [meta, setMeta] = useState<SignUpMeta>({
    c_email: "",
    email: "",
    registeration_number: 0,
    phonenumber: 0,
    website_link: "",
    password: "",
    type: "",
    commission: 0,
    id_card: "",
    business_license: "",
    username: "",
  });

  const onSignUpMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setMeta({
      ...meta,
      [name]: value,
    });
  };

  useEffect(() => {
    setMeta({
      ...meta,
      id_card: idCard || "",
    });
  }, [idCard]);

  useEffect(() => {
    setMeta({
      ...meta,
      business_license: businessLicense || "",
    });
  }, [businessLicense]);

  const navigate = useNavigate();

  const onSignUp = () => {
    // if (!isSigningUp) {
    setIsSigningUp(true);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/transport/register`, meta, {
        withCredentials: true,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          toast.success(
            "User Registered Successfully. Your account is on approval wait for our confirmation"
          );

          localStorage.setItem("doyoofToken", "Bearer " + response.data.token);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(response.data?.error);
        }
        setIsSigningUp(false);
      })
      .catch((error: AxiosError<ErrorResponseData>) => {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
        setIsSigningUp(false);
      });
    // }
  };

  const handleFileChangeIDCard = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result as string;
        setIDCard(dataURL);
      };
    }
  };

  const handleFileChangeBusinessLicense = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result as string;
        setBusinessLicense(dataURL);
      };
    }
  };

  return (
    <>
      {loading && <Loading />}
      <AuthLayout>
        <div className="flex flex-col items-center h-screen py-5 my-4 xl:h-auto xl:py-0 xl:my-0 gap-12 md:gap-6">
          <img
            alt="Midone Tailwind HTML Admin Template"
            src={logoUrl}
            className="xl:hidden w-5/12 md:w-48 cursor-pointer"
          />
          <div className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
              Register Your Company With Doyoof
            </h2>
            <div className="mt-4 intro-x">
              <FormLabel className="text-gray-600" htmlFor="username">
                Username
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Username"
                name="username"
                value={meta.username}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="email">
                Email
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Email"
                name="email"
                value={meta.email}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="c_email">
                Company Email
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Company Email"
                name="c_email"
                value={meta.c_email}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="phonenumber">
                Phonenumber
              </FormLabel>
              <FormInput
                type="number"
                className="block px-4 py-3 mt-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Phonenumber"
                name="phonenumber"
                value={meta.phonenumber}
                onChange={onSignUpMetaChange}
              />
              <FormLabel
                className="text-gray-600 mt-3"
                htmlFor="registeration_number"
              >
                Business Registration Number
              </FormLabel>
              <FormInput
                type="number"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Business Registration Number"
                name="registeration_number"
                value={meta.registeration_number}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="website_link">
                Website Link
              </FormLabel>
              <FormInput
                type="text"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Website Link"
                name="website_link"
                value={meta.website_link}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="password">
                Password
              </FormLabel>
              <FormInput
                type="password"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Password"
                name="password"
                value={meta.password}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="commission">
                How much Commission You will give to us? Enter in %
              </FormLabel>
              <FormInput
                type="number"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                placeholder="Commission"
                name="commission"
                value={meta.commission}
                onChange={onSignUpMetaChange}
              />
              <FormLabel className="text-gray-600 mt-3" htmlFor="id_card">
                Upload ID card of Business Owner
              </FormLabel>
              <FormInput
                type="file"
                id="id_card"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                name="id_card"
                onChange={handleFileChangeIDCard}
              />
              <FormLabel
                className="text-gray-600 mt-3"
                htmlFor="business_license"
              >
                Upload Scanned Copy of Business License
              </FormLabel>
              <FormInput
                type="file"
                id="business_license"
                className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
                name="business_license"
                onChange={handleFileChangeBusinessLicense}
              />
              <FormLabel htmlFor="type" className="mr-2 mt-2">
                Register as a
              </FormLabel>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="transport"
                  name="type"
                  value="Transport"
                  checked={meta.type === "Transport"}
                  onChange={onSignUpMetaChange}
                />
                <label htmlFor="transport" className="ml-1 mr-3">
                  Transport Office
                </label>
                <input
                  type="radio"
                  id="carrental"
                  name="type"
                  value="CarRental"
                  checked={meta.type === "CarRental"}
                  onChange={onSignUpMetaChange}
                />
                <label htmlFor="carrental" className="ml-1">
                  Car Rental Office
                </label>
              </div>
            </div>
            <div className="mt-5 text-center intro-x xl:mt-8 xl:text-left">
              <Button
                onClick={onSignUp}
                variant="primary"
                disabled={isSigningUp}
                className="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
              >
                {isSigningUp ? (
                  <Loader className="w-5 h-5 text-white" />
                ) : (
                  "Register"
                )}
              </Button>
              <Button
                onClick={() => navigate("/login")}
                variant="outline-secondary"
                className="w-full px-4 py-3 mt-3 align-top xl:w-32 xl:mt-0"
              >
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Main;
