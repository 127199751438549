// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@fortawesome/fontawesome-free/js/all.min.js";

import { NavLink } from "react-router-dom";
import Table from "../../base-components/Table";
import { useEffect, useState } from "react";
import { Dialog } from "../../base-components/Headless";
import Lucide from "../../base-components/Lucide";
import Button from "../../base-components/Button";
import axios from "axios";
import { useDecodedToken } from "../../hooks/useDecodedToken";

const TransportDriver = () => {
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);

  const [drivers, setDrivers] = useState([]);
  console.log("drivers: ", drivers);
  const decodedToken = useDecodedToken();

  useEffect(() => {
 
    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/drivers?transportUserId=${decodedToken?.id}`
        );

        console.log(response.data);
        if (response.status === 200) {
          setDrivers(response.data.data);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, [decodedToken]);
  async function itemDeleteHandler() {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/drivers/${deleteItemId}`
    );
    if (response.status === 200) {
      const filter = drivers.filter((item) => item.id !== deleteItemId);
      setDrivers(filter);
    }
  }

  const updateStatus = async (id: number, status: string) => {
    console.log("Status", status);
    console.log("id", id);
    // setLoading(true);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/drivers/update-status`,
        { id, status }
      );
      // setLoading(false)
      // Refresh the current page
      window.location.reload();
    } catch (error) {
      console.error("Failed to update additional price:", error);
      // setLoading(false);
    }
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between md:items-center my-4">
          <div className="title">
            <h1 className="text-2xl font-bold mb-4 text-center md:text-start">
              Transport Driver Information
            </h1>
          </div>
          <div className="add-btn self-end md:self-center">
            <NavLink to="/create-driver">
              <button className=" p-2 px-4 rounded-md text-white bg-green-600 text-sm flex items-center hover:bg-opacity-90 duration-300">
                <i className="fa-solid fa-plus mr-1"></i>
                Add Transport Driver
              </button>
            </NavLink>
          </div>
        </div>

        <div className="overflow-x-auto">
          <Table hover>
            <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
              <Table.Tr>
                <Table.Th className="whitespace-nowrap">#</Table.Th>
                <Table.Th className="whitespace-nowrap">Name</Table.Th>
                <Table.Th className="whitespace-nowrap">License No.</Table.Th>
                <Table.Th className="whitespace-nowrap">Phone Number</Table.Th>

                <Table.Th className="whitespace-nowrap">Address</Table.Th>
                <Table.Th className="whitespace-nowrap">cnic</Table.Th>
                <Table.Th className="whitespace-nowrap">Details</Table.Th>
                <Table.Th className="whitespace-nowrap">status</Table.Th>
                <Table.Th className="whitespace-nowrap">Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {drivers && drivers.length === 0 ? (
                <Table.Tr>
                  <Table.Td colSpan={8} className="text-center">
                    Currently no Transport driver added
                  </Table.Td>
                </Table.Tr>
              ) : (
                drivers.length > 0 &&
                drivers?.map((driver, index) => {
                  return (
                    <Table.Tr key={driver?.id} className="text-center text-xs">
                      <Table.Td>{index + 1}</Table.Td>
                      <Table.Td>{driver?.name}</Table.Td>
                      <Table.Td>{driver?.License_no}</Table.Td>
                      <Table.Td>{driver?.phonenumber}</Table.Td>
                      <Table.Td>{driver?.address}</Table.Td>
                      <Table.Td>{driver?.cnic}</Table.Td>

                      <Table.Td>
                        <NavLink
                          to={`/view-driver/${driver?.id}`}
                          state={driver}
                        >
                          <button className="bg-success p-2 rounded-md text-white flex items-center">
                            <i className="fa-solid fa-eye pe-1"></i>
                            View
                          </button>
                        </NavLink>
                      </Table.Td>
                      <Table.Td className="justify-center items-center">
                        <NavLink to="">
                          <button
                            onClick={() => {
                              updateStatus(driver.id, driver.status);
                            }}
                            className={`p-2 rounded-md text-white flex items-center ${
                              driver.status === "Pending"
                                ? "bg-warning"
                                : "bg-success"
                            }`}
                          >
                            {driver.status}
                          </button>
                        </NavLink>
                      </Table.Td>

                      <Table.Td>
                        <div className="flex items-center justify-center">
                          <NavLink
                            to={`/edit-driver/${driver?.id}`}
                            state={driver}
                          >
                            <button className="flex items-center mr-3">
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                          </NavLink>

                          <button
                            onClick={() => {
                              // itemDeleteHandler(driver?.id);
                              setDeleteItemId(driver?.id);
                              setIsModelOpen(true);
                            }}
                            className="flex items-center text-danger"
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })
              )}
            </Table.Tbody>
          </Table>
        </div>
      </div>
      <Dialog
        open={isModelOpen}
        onClose={() => {
          setIsModelOpen(false);
        }}
        className={"pt-4"}
      >
        <Dialog.Panel className={"top-12"}>
          <div className="p-5 text-center">
            <Lucide
              icon="XCircle"
              className="w-16 h-16 mx-auto mt-3 text-danger"
            />
            <div className="mt-5 text-3xl">Are you sure?</div>
            <div className="mt-2 text-slate-500">
              Do you really want to delete these records? <br />
              This process cannot be undone.
            </div>
          </div>
          <div className="px-5 pb-8 text-center">
            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => {
                setIsModelOpen(false);
                console.log("Cancel");
              }}
              className="w-24 mr-1"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="danger"
              className="w-24"
              onClick={() => {
                if (deleteItemId) {
                  itemDeleteHandler();
                }
                setIsModelOpen(false);
                console.log("Delete");
              }}
            >
              Delete
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default TransportDriver;
