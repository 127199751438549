import React from "react";
import { NavLink } from "react-router-dom";

const AddButton = ({ btnTitle, redirectTo }:any) => {
  return (
    <div className="add-btn self-end md:self-center">
      <NavLink to={redirectTo}>
        <button className=" p-2 px-4 rounded-md text-white bg-green-600 text-sm flex items-center hover:bg-opacity-90 duration-300">
          <i className="fa-solid fa-plus mr-1"></i>
          {btnTitle}
        </button>
      </NavLink>
    </div>
  );
};

export default AddButton;
