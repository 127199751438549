import React, { useState } from "react";
import { FormInput, FormLabel } from "../../base-components/Form";
import useFetch from "../../hooks/useFetch";

interface reservedDataMeta {
  note: string;
  start_date: string;
  end_date: string;
  quantity: number;
}

const Reserved: React.FC<any> = ({ reservedData, setReservedData, mode }) => {
  //   const [reservedData, setReservedData] = useState<reservedDataMeta[]>([
  //     { note: "", start_date: "", end_date: "", quantity: 0 },
  //   ]);
  const isViewPage = mode === 'view'

  const handleReservedDataChange = (
    index: number,
    field: keyof reservedDataMeta,
    value: string
  ) => {
    const updatedRows: any = [...reservedData];
    updatedRows[index][field] = value;
    setReservedData(updatedRows);
  };

  const handleAddReserved = () => {
    setReservedData([
      ...reservedData,
      { note: "", start_date: "", end_date: ""},
    ]);
  };

  return (
    <>
      <div className="col-span-6 md:col-span-6 title my-8 w-full">
        <div className=" text-2xl text-gray-600 font-bold">Reserved</div>
        <div className="mutted w-full text-xs text-mutted text-gray-400">
          Please fill the required fields
        </div>
      </div>
      <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
        {reservedData.map((row, index) => (
          <div
            key={index}
            // className="col-span-8 md:col-span-8 w-full grid grid-cols-8 gap-3"
            className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3"
          >
            {/* <div className="col-span-3 md:col-span-2"> */}
            <div className="col-span-3 md:col-span-2">

              <FormLabel htmlFor={`note${index}`}>
                Reserved Booking Note
              </FormLabel>
              <FormInput
                id={`note${index}`}
                name={`note${index}`}
                value={row.note}
                onChange={(e) =>
                  handleReservedDataChange(index, "note", e.target.value)
                }
                placeholder="Reserved Booking Note"
                disabled={isViewPage}

              />
            </div>
            {/* <div className="col-span-2 flex flex-col"> */}
            <div className="col-span-2 flex flex-col">
              <FormLabel htmlFor={`start_date${index}`}>
                Reserved Start Date
              </FormLabel>
              <input
                type="date"
                id={`start_date${index}`}
                name={`start_date${index}`}
                className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
                value={row.start_date}
                onChange={(e) =>
                  handleReservedDataChange(index, "start_date", e.target.value)
                }
                placeholder="reservedstart_date"
                disabled={isViewPage}

              />
            </div>
            {/* <div className="col-span-3 flex flex-col"> */}
            <div className="col-span-3 md:col-span-2 flex flex-col">
              <FormLabel htmlFor={`end_date${index}`}>
                Reserved End Date
              </FormLabel>
              <input
                type="date"
                id={`end_date${index}`}
                name={`end_date${index}`}
                className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
                value={row.end_date}
                onChange={(e) =>
                  handleReservedDataChange(index, "end_date", e.target.value)
                }
                placeholder="Reserved End Date"
                disabled={isViewPage}

              />
            </div>
            {/* <div className="col-span-2 flex flex-col">
              <FormLabel htmlFor={`quantity${index}`}>Room Quantity</FormLabel>
              <input
                type="number"
                id={`quantity${index}`}
                name={`quantity${index}`}
                className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
                value={row.quantity}
                onChange={(e) =>
                  handleReservedDataChange(index, "quantity", e.target.value)
                }
                placeholder="Room quantity"
              />
            </div> */}
          </div>
        ))}
        <p
          style={{ cursor: "pointer" }}
          onClick={handleAddReserved}
          className="text-blue-600 w-full mt-5"
        >
          Add New Item
        </p>
      </div>
    </>
  );
};

export default Reserved;
