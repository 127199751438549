import { ChangeEvent, useRef, useState, useEffect } from "react";
import { PreviewComponent } from "../../base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormTextarea,
  FormSelect,
} from "../../base-components/Form";
import toast from "react-hot-toast";
import Button from "../../base-components/Button";
import Loader from "../../base-components/Loader";
import { DropzoneFile } from "dropzone";
import Dropzone, { DropzoneElement } from "../../base-components/Dropzone";
import Progress from "../../base-components/Progress";
import Dialog from "../../base-components/Headless/Dialog";
import LoadingIcon from "../../base-components/LoadingIcon";
import Loading from "../../components/Loading";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Input, Select } from "antd";
import CustomFormInput from "../../custom-components/CustomFormInput";
import Dropdown from "../../custom-components/Dropdown";
import axios from "axios";
import SeasonalPrice from "./SeasonalPrice";
import AdditionalPrice from "./AdditionalPrice";
import Reserved from "./Reserved";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

export interface createCarRentalMeta {
  car_name: string;
  car_company: string;
  car_category: string;
  model: string;
  door: string;
  ac: boolean;
  gear: string;
  Luggage_space: string;
  price: string;
  admin_comission: string;
  refundable: boolean;
  free_cancellation: string;
  cancellation_end_time: string;
  discount: string;
  discount_type: string;
  pre_payment: string;
  half_payment: string;
  half_payment_time: string;
  description: string;
  color: string;
  ratings: string;
  seats: string;

  // additionalPrice: string[];
  // additionalLabel: string[];
  // additionalType: string[];
  additional_prices: any[];
  primary_protection_price: number | string;
  cover_damage_theft_price: number | string;
  cover_all_driver_price: number | string;
  images?: DropzoneFile[];
}

interface IAdditionalPrice {
  id: number;
  additional_price_name: string;
  additional_price: number;
  additional_type: string;
}

interface seasonalDataMeta {
  price: string;
  start_date: string;
  end_date: string;
}

interface reservedDataMeta {
  note: string;
  start_date: string;
  end_date: string;
}

const CarRentalForm = ({ mode }: any) => {
  const isViewPage = mode === "view";
  const isAddMode = mode === "add";
  const [isAddingCar, setIsAddingCar] = useState<boolean>(false);
  const dropzoneRef = useRef<DropzoneElement>();
  const { Option } = Select;
  const [meta, setMeta] = useState<createCarRentalMeta>({
    car_name: "",
    car_company: "",
    car_category: "",
    model: "",
    door: "",
    ac: false,
    gear: "",
    Luggage_space: "",
    price: "",
    admin_comission: "",
    refundable: false,
    free_cancellation: "",
    cancellation_end_time: "",
    discount: "",
    discount_type: "percentage",
    pre_payment: "",
    half_payment: "",
    half_payment_time: "",
    description: "",
    color: "",
    ratings: "",
    seats: "",
    additional_prices: [],
    primary_protection_price: "",
    cover_damage_theft_price: "",
    cover_all_driver_price: "",
    // additionalPrice: [],
    // additionalLabel: [],
    // additionalType: [],

    images: [],
  });

  const [seasonalData, setSeasonalData] = useState<seasonalDataMeta[]>([
    { price: "", start_date: "", end_date: "" },
  ]);

  const [reservedData, setReservedData] = useState<reservedDataMeta[]>([
    { note: "", start_date: "", end_date: "" },
  ]);

  const [selectedAdditionalPrices, setSelectedAdditionalPrices] = useState<
    IAdditionalPrice[]
  >([]);

  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    if (mode === "edit" || mode === "view") {
      const { reservedCars, seasonalPrices, car } = location.state;
      // const {  car } = location.state;
      setMeta({
        ...car,
        // image: location.state.image || null,F
      });
      const filteredReservedData = reservedCars.filter(
        (r: any) => r.car_id == car.id
      );
      const filteredSeasonalData = seasonalPrices.filter(
        (s: any) => s.car_id == car.id
      );

      setReservedData(filteredReservedData);
      setSeasonalData(filteredSeasonalData);
      setSelectedAdditionalPrices(JSON.parse(car?.additional_prices));
    }
  }, [mode, location.state]);

  if (dropzoneRef.current) {
    dropzoneRef.current.dropzone.on("addedfiles", () => {
      const files = dropzoneRef?.current?.dropzone.getAcceptedFiles();
      if (files) {
        setMeta({
          ...meta,
          images: files,
        });
      }
    });
  }

  useEffect(() => {}, []);

  const onMetaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    console.log("meta", meta);

    let newValue: string | number | boolean = value;

    if (value === "true") {
      newValue = true;
    } else if (value === "false") {
      newValue = false;
    }

    const isCheckbox = type === "checkbox";
    setMeta((prevMeta) => ({
      ...prevMeta,
      [name]: isCheckbox ? (e.target as HTMLInputElement).checked : newValue,
    }));
  };

  const navigate = useNavigate();
  const [categories]: any[] = useFetch("car-rental/category");

  const onSubmitCarRental = async () => {
    if (!isAddingCar) {
      if (meta.car_name === "") {
        toast.error("Car Name is required");
      } else if (meta.car_company === "") {
        toast.error("Car Company is required");
      } else if (meta.car_category === "") {
        toast.error("Car Category is required");
      } else if (meta.model === "") {
        toast.error("Model is required");
      } else if (meta.door === "") {
        toast.error("Number of Doors is required");
      } else if (meta.gear === "") {
        toast.error("Gear Type is required");
      } else if (meta.Luggage_space === "") {
        toast.error("Luggage Space is required");
      } else if (meta.price === "") {
        toast.error("Price is required");
      } else if (meta.admin_comission === "") {
        toast.error("Admin Comission is required");
      } else if (meta.description === "") {
        toast.error("Description is required");
      } else if (meta.color === "") {
        toast.error("Color is required");
      } else if (meta.ratings === "") {
        toast.error("Ratings is required");
      } else if (meta.seats === "") {
        toast.error("Seats is required");
      } else if (
        meta.primary_protection_price === "" ||
        meta.primary_protection_price === 0
      ) {
        toast.error("Primary protection price is required");
      } else if (
        meta.cover_all_driver_price === "" ||
        meta.cover_all_driver_price === 0
      ) {
        toast.error("Cover All Driver price is required");
      } else if (
        meta.cover_damage_theft_price === "" ||
        meta.cover_damage_theft_price === ""
      ) {
        toast.error("Cover Damage theft price is required");
      } else {
        setIsAddingCar(true);
        // const dataToSend: createCarRentalMeta[] = meta;
        // dataToSend.additional_prices = selectedAdditionalPrices;
        const dataToSend = {
          ...meta,
          additional_prices: selectedAdditionalPrices,
        };
        try {
          const httpMethod = isAddMode ? "post" : "put";
          const response1 = await axios[httpMethod](
            `${process.env.REACT_APP_BACKEND_URL}/car-rental/${
              isAddMode ? "" : location.state.car.id
            }`,
            dataToSend
          );
          const car_id = isAddMode
            ? response1?.data?.id
            : location.state.car.id;
          console.log("response1", response1);

          const response2 = axios[httpMethod](
            `${process.env.REACT_APP_BACKEND_URL}/car-rental/reserved-array`,
            // reservedData
            reservedData.map((r) => {
              return { ...r, car_id };
            })
          );

          const response3 = axios[httpMethod](
            `${process.env.REACT_APP_BACKEND_URL}/car-rental/seasonal-price-array`,
            // seasonalData
            seasonalData.map((s) => {
              return { ...s, car_id };
            })
          );
          console.log("dataToSend", dataToSend);
          console.log("reservedData", reservedData);
          console.log("seasonalData", seasonalData);

          const allPromiseResponse = await Promise.all([
            response1,
            response2,
            response3,
          ]);
          console.log("allPromiseResponse", allPromiseResponse);
          const successStatusCode = isAddMode ? 201 : 200;
          if (response1.status === successStatusCode) {
            navigate("/car-rental");
            toast.success(
              `Car rental ${isAddMode ? "added" : "updated"} successfully!`
            );
          }
          // console.log("response", response);
          // if (response.status === 201) {
          //   // const data = await response.json();
          //   toast.success("Car rental added successfully!");
          //   setMeta({
          //     car_name: "",
          //     car_company: "",
          //     car_category: "",
          //     model: "",
          //     door: "",
          //     ac: false,
          //     gear: "",
          //     Luggage_space: "",
          //     price: "",
          //     admin_comission: "",
          //     refundable: false,
          //     free_cancellation: "",
          //     cancellation_end_time: "",
          //     discount: "",
          //     discount_type: "",
          //     pre_payment: "",
          //     half_payment: "",
          //     half_payment_time: "",
          //     description: "",
          //     color: "",
          //     ratings: "",
          //     seats: "",

          //     // additionalPrice: [],
          //     // additionalLabel: [],
          //     // additionalType: [],

          //     images: [],
          //   });
          // } else {
          //   toast.error("Failed to add car rental..");
          // }
        } catch (error) {
          toast.error("Failed to add car rental.");
        } finally {
          setIsAddingCar(false);
        }
      }
    }
  };

  return (
    <>
      {isAddingCar && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">
            {isAddMode ? "Create New Car" : meta.car_name}
          </h1>
          <div className="w-full grid grid-cols-6 gap-4 pt-6">
            <div className="title my-3 w-full col-span-6 md:col-span-6">
              <div className=" text-2xl text-gray-600 font-bold">
                Basic Info
              </div>
              <div className="mutted w-full text-xs text-mutted text-gray-400">
                Please fill the required fields
              </div>
            </div>
            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              <CustomFormInput
                label="Car Name"
                name="car_name"
                type="text"
                value={meta?.car_name}
                disabled={isViewPage}
                onChange={onMetaChange}

                // threeCol={true}
              />

              <CustomFormInput
                label="Car Company"
                name="car_company"
                type="text"
                value={meta?.car_company}
                disabled={isViewPage}
                onChange={onMetaChange}
              />

              {/* <CustomFormInput
                label="Car Category"
                name="car_category"
                type="text"
                value={meta?.car_category}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              /> */}
              <div className={"col-span-4 md:col-span-2"}>
                <FormLabel className="text-gray-500" htmlFor={"car_category"}>
                  Car Category
                </FormLabel>
                <FormSelect
                  id={"car_category"}
                  name={"car_category"}
                  value={meta?.car_category}
                  onChange={onMetaChange}
                  disabled={isViewPage}
                >
                  {categories?.map((category: any, index: any) => {
                    return (
                      <option key={index} value={String(category.name)}>
                        {category.name}
                      </option>
                    );
                  })}
                </FormSelect>
              </div>
              <CustomFormInput
                label="Car Model"
                name="model"
                type="text"
                value={meta?.model}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Primary Protection Price"
                name="primary_protection_price"
                type="number"
                value={meta?.primary_protection_price}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Cover All Driver Price"
                name="cover_all_driver_price"
                type="number"
                value={meta?.cover_all_driver_price}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />
              <CustomFormInput
                label="Cover Damage Theft Price"
                name="cover_damage_theft_price"
                type="number"
                value={meta?.cover_damage_theft_price}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Car Color"
                name="color"
                type="text"
                value={meta?.color}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Doors"
                name="door"
                type="number"
                value={meta?.door}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />
              <CustomFormInput
                label="Seats"
                name="seats"
                type="number"
                value={meta?.seats}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <Dropdown
                label="AC"
                name="ac"
                value={meta?.ac}
                onChange={(e) => {
                  console.log("2", e.target.value);
                  onMetaChange(e);
                }}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                threeCol={true}
                disabled={isViewPage}
              />

              <CustomFormInput
                label="Gears"
                name="gear"
                type="number"
                value={meta?.gear}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Luggage Space"
                name="Luggage_space"
                type="number"
                value={meta?.Luggage_space}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Price"
                name="price"
                type="number"
                value={meta?.price}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Admin Comission"
                name="admin_comission"
                type="number"
                value={meta?.admin_comission}
                disabled={isViewPage}
                threeCol={true}
                onChange={onMetaChange}
              />

              <CustomFormInput
                label="Discount"
                name="discount"
                type="number"
                value={meta?.discount}
                disabled={isViewPage}
                onChange={onMetaChange}
                threeCol={true}
              />

              <Dropdown
                label="Discount Type"
                name="discount_type"
                value={meta?.discount_type}
                onChange={(e) => onMetaChange(e)}
                options={[
                  { label: "Percentage", value: "percentage" },
                  { label: "Regular", value: "regular" },
                ]}
                threeCol={true}
                disabled={isViewPage}
              />

              <Dropdown
                label="Refundable"
                name="refundable"
                value={meta?.refundable}
                onChange={(e) => onMetaChange(e)}
                options={[
                  { label: "Refundable", value: true },
                  { label: "Non Refundable", value: false },
                ]}
                threeCol={true}
                disabled={isViewPage}
              />

              <div className="col-span-3 md:col-span-2">
                <FormLabel className="text-gray-500" htmlFor="ratings">
                  Select Ratings
                </FormLabel>

                <FormSelect
                  id="ratings"
                  name="ratings"
                  value={meta.ratings}
                  onChange={(e) => onMetaChange(e)}
                  disabled={isViewPage}
                >
                  <option value="">Select Ratings</option>
                  <option value="Superb">Superb: 9+</option>
                  <option value="Very good">Very good: 8+</option>
                  <option value="Good">Good: 7+</option>
                  <option value="Pleasant">Pleasant: 6+</option>
                </FormSelect>
              </div>

              <div className="col-span-12 md:col-span-4">
                <FormLabel htmlFor="parking">Free Cancelation</FormLabel>

                <FormSelect
                  onChange={(e: any) => onMetaChange(e)}
                  name="free_cancellation"
                  id="free_cancellation"
                  value={meta.free_cancellation}
                  disabled={isViewPage}
                >
                  <option value="">Select Free Cancelation</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </FormSelect>
                {meta.free_cancellation == "Yes" && (
                  <div className="col-span-12 md:col-span-4 mt-3">
                    <FormLabel htmlFor="cancellation_end_time">
                      Free Cancelation Max Days
                    </FormLabel>
                    <FormInput
                      type="number"
                      id="cancellation_end_time"
                      name="cancellation_end_time"
                      min={0}
                      value={meta.cancellation_end_time}
                      onChange={(e) => onMetaChange(e)}
                      placeholder="Free Cancelation Max Days"
                      disabled={isViewPage}
                    />
                  </div>
                )}
              </div>

              <div className="col-span-6 mt-3">
                <FormLabel className="text-gray-500" htmlFor="description">
                  Description
                </FormLabel>
                <FormTextarea
                  id="description"
                  name="description"
                  rows={3}
                  value={meta?.description}
                  onChange={(e) => onMetaChange(e)}
                  placeholder="description"
                  disabled={isViewPage}
                />
              </div>
            </div>

            <div className=" col-span-6 md:col-span-6  title my-8 w-full">
              <div className=" text-2xl text-gray-600 font-bold">
                Other Services
              </div>
              <div className="mutted w-full text-xs text-mutted text-gray-400">
                Please fill the required fields
              </div>
            </div>

            <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-12 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
              <div className="col-span-12 md:col-span-12   flex items-center">
                <FormLabel htmlFor="prePayments" className="mr-2 mt-2">
                  Pre Payments
                </FormLabel>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="prePaymentsYes"
                    name="pre_payment"
                    value="Yes"
                    checked={meta.pre_payment === "Yes"}
                    onChange={(e) => onMetaChange(e)}
                    disabled={isViewPage}
                  />
                  <label htmlFor="prePaymentsYes" className="ml-1 mr-3">
                    Yes
                  </label>
                  <input
                    type="radio"
                    id="prePaymentsNo"
                    name="pre_payment"
                    value="No"
                    checked={meta.pre_payment === "No"}
                    onChange={(e) => onMetaChange(e)}
                    disabled={isViewPage}
                  />
                  <label htmlFor="prePaymentsNo" className="ml-1">
                    No
                  </label>
                </div>
              </div>
              {meta.pre_payment == "No" && (
                <div className="col-span-12 md:col-span-12 flex items-center">
                  <FormLabel htmlFor="prePayments" className="mr-2 mt-2">
                    Half Payment
                  </FormLabel>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="halfPaymentsYes"
                      name="half_payment"
                      value="Yes"
                      checked={meta.half_payment === "Yes"}
                      onChange={(e) => onMetaChange(e)}
                      disabled={isViewPage}
                    />
                    <label htmlFor="halfPaymentsYes" className="ml-1 mr-3">
                      Yes
                    </label>
                    <input
                      type="radio"
                      id="halfPaymentsNo"
                      name="half_payment"
                      value="No"
                      checked={meta.half_payment === "No"}
                      onChange={(e) => onMetaChange(e)}
                      disabled={isViewPage}
                    />
                    <label htmlFor="halfPaymentsNo" className="ml-1">
                      No
                    </label>
                  </div>
                </div>
              )}
              {meta.half_payment == "Yes" && (
                <div className="col-span-12 md:col-span-2">
                  <FormLabel
                    className="text-gray-500"
                    htmlFor="half_payment_time"
                  >
                    Remaining payment automatically cut on how many days?
                  </FormLabel>
                  <FormInput
                    type="number"
                    id="half_payment_time"
                    name="half_payment_time"
                    value={meta.half_payment_time}
                    onChange={(e) => onMetaChange(e)}
                    disabled={isViewPage}
                  />
                </div>
              )}
            </div>

            <SeasonalPrice
              seasonalData={seasonalData}
              setSeasonalData={setSeasonalData}
              mode={mode}
            />

            <AdditionalPrice
              setMeta={setMeta}
              selectedAdditionalPrices={selectedAdditionalPrices}
              setSelectedAdditionalPrices={setSelectedAdditionalPrices}
              mode={mode}
            />

            <Reserved
              reservedData={reservedData}
              setReservedData={setReservedData}
              mode={mode}
            />

            {isAddMode ? (
              <div className="col-span-6">
                <FormLabel className="text-gray-500" htmlFor="images">
                  Car Images
                </FormLabel>
                <Dropzone
                  id="images"
                  getRef={(el) => {
                    dropzoneRef.current = el;
                  }}
                  options={{
                    url: "https://httpbin.org/post",
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    headers: { "My-Awesome-Header": "header value" },
                  }}
                  className="dropzone"
                >
                  <div className="text-lg font-medium">
                    Drop files here or click to upload.
                  </div>
                </Dropzone>
              </div>
            ) : (
              ""
            )}

            {isViewPage ? (
              ""
            ) : (
              <div className="col-span-6 justify-self-end">
                <Button
                  className="bg-green-600 text-white"
                  onClick={onSubmitCarRental}
                >
                  {isAddMode ? "Add Car" : "Udpate car"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </PreviewComponent>
    </>
  );
};

export default CarRentalForm;
