import React, { useState } from "react";
import { FormInput, FormLabel } from "../../base-components/Form";

const SeasonalPrice: React.FC<any> = ({ seasonalData, setSeasonalData, mode }) => {
  const isViewPage = mode === 'view'

  const handleSeasonalDataChange = (
    index: number,
    field:  any,
    value: string
  ) => {
    const updatedRows = [...seasonalData];
    updatedRows[index][field] = value;
    setSeasonalData(updatedRows);
  };

  const handleAddSeasonal = () => {
    setSeasonalData([
      ...seasonalData,
      { price: "", start_date: "", end_date: "" },
    ]);
  };

  return (
    <>
      <div className=" col-span-6 md:col-span-6 title my-14 w-full">
        <div className=" text-2xl text-gray-600 font-bold">Seasonal Price</div>
        <div className="mutted w-full text-xs text-mutted text-gray-400">
          Please fill the required fields
        </div>
      </div>
      <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
        {seasonalData.map((row: any, index: any) => (
          <div
            key={index}
            className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3"
          >
            <div className="col-span-3 md:col-span-2">
              <FormLabel htmlFor={`price${index}`}>Seasonal Price</FormLabel>
              <FormInput
                type="text"
                id={`price${index}`}
                name={`price${index}`}
                value={row.price}
                onChange={(e) =>
                  handleSeasonalDataChange(index, "price", e.target.value)
                }
                placeholder="Enter Price"
                disabled={isViewPage}

              />
            </div>
            <div className="col-span-2 flex flex-col">
              <FormLabel htmlFor={`start_date${index}`}>
                Seasonal Start Date
              </FormLabel>
              <input
                type="date"
                value={row.start_date}
                name={`start_date${index}`}
                id={`start_date${index}`}
                onChange={(e) =>
                  handleSeasonalDataChange(index, "start_date", e.target.value)
                }
                className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
                disabled={isViewPage}

              />
            </div>
            <div className="col-span-3 md:col-span-2 flex flex-col">
              <FormLabel htmlFor={`end_date${index}`}>
                Seasonal End date
              </FormLabel>
              <input
                type="date"
                id={`end_date${index}`}
                name={`end_date${index}`}
                value={row.end_date}
                onChange={(e) =>
                  handleSeasonalDataChange(index, "end_date", e.target.value)
                }
                className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
                placeholder="Seasonal End Date"
                disabled={isViewPage}

              />
            </div>
          </div>
        ))}
        <p
          style={{ cursor: "pointer" }}
          onClick={handleAddSeasonal}
          className="text-blue-600 w-full mt-5"
        >
          Add New Item
        </p>
      </div>
    </>
  );
};

export default SeasonalPrice;
