export const getMonthlyTotals = (bookings) => {
  const monthlyTotals = Array(12).fill(0);
  console.log("bookings,", bookings);

  bookings.forEach((booking) => {
    const bookingDate = new Date(booking.booking_date);
    const month = bookingDate.getMonth();
    const amount = booking.total_price || 0;

    // Add the amount to the respective month's total
    monthlyTotals[month] += amount;
  });

  console.log("monthlyTotals{", monthlyTotals);

  return monthlyTotals;
};
