import React, { useEffect } from 'react';
import Loader from '../../base-components/Loader'

function Loading() {
  useEffect(() => {
    // Disable scrolling when loader is displayed
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts (loader is hidden)
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-300 bg-opacity-80 z-50">
   
      <Loader className="  w-14" />
  
  </div>
  )
}

export default Loading


