// Initialize monthly totals
export const initializeMonthlyTotals = (months: any) => {
  return months.reduce((acc: any, month) => {
    acc[month] = {
      total: 0,
      status: "",
      year: new Date().getFullYear(),
      month: 0,
    };
    return acc;
  }, {});
};
