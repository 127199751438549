import axios from "axios";
import { useEffect, useState } from "react";

import { months } from "../../../data/StaticData";
import { useDecodedToken } from "../../../hooks/useDecodedToken";
import { handleSearch } from "../../../helper/handleYearSearch";
import { calculateFinancials } from "../../../helper/calculateFinancials";
import { initializeMonthlyTotals } from "../../../helper/initializeMonthlyTotals";

import Heading from "../../../custom-components/Finance/Heading";
import Loading from "../../../components/Loading";
import SearchByYear from "../Component/SearchByYear";
import DataTable from "../Component/DataTable";

function ReceiveFromAdmin() {
  const [loading, setLoading] = useState<boolean>(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [bookings, setBookings] = useState<any[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<any[]>([]);

  const [monthlyTotals, setMonthlyTotals] = useState(() =>
    initializeMonthlyTotals(months)
  );

  const decodedToken = useDecodedToken();

  useEffect(() => {
    if (!decodedToken) return console.log("decoded token is null");

    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/trip_bookings?userId=${decodedToken?.id}`
        );

        if (response.status === 200) {
          setBookings(response.data?.data || []);
          setFilteredBookings(response.data?.data || []);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, [decodedToken]);

  const updateMonthlyTotals = (booking: any) => {
    const totalPrice = booking?.total_price || 0;

    const { credit, commisionAmount: commission } = calculateFinancials(
      booking?.our_commission,
      totalPrice
    );

    const bookingDate = new Date(booking.booking_date);
    const bookingMonth = bookingDate.toLocaleString("default", {
      month: "long",
    });
    const bookingYear = bookingDate.getFullYear();

    setMonthlyTotals((prevTotals) => {
      const updatedTotals = { ...prevTotals };

      if (!updatedTotals[bookingMonth]) {
        updatedTotals[bookingMonth] = {
          total: 0,
          status: "",
          year: bookingYear,
          month: bookingDate.getMonth() + 1,
        };
      }

      updatedTotals[bookingMonth].total += credit;
      updatedTotals[bookingMonth].status = booking.paid || "pending";
      updatedTotals[bookingMonth].year = bookingYear;

      return updatedTotals;
    });
  };

  useEffect(() => {
    setMonthlyTotals(initializeMonthlyTotals(months));

    filteredBookings.forEach(updateMonthlyTotals);
  }, [filteredBookings]);

  return (
    <>
      {loading == true && <Loading />}
      <div className="container mx-auto">
        <Heading heading="Receive Payment From Admin" />

        <SearchByYear
          year={year}
          setYear={setYear}
          handleSearch={handleSearch}
          bookings={bookings}
          setFilteredBookings={setFilteredBookings}
        />

        <div className="overflow-x-auto">
          <DataTable
            bookings={filteredBookings}
            months={months}
            monthlyTotals={monthlyTotals}
          />
        </div>
      </div>
    </>
  );
}

export default ReceiveFromAdmin;
