export const calculateFinancials = (commission: number, price: number) => {
  const commisionAmount =
    parseFloat(((commission / 100) * price).toFixed(1)) || 0;
  const credit = price - commisionAmount || 0;

  return {
    credit,
    commisionAmount,
  };
};
