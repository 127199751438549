export const handleSearch = (
  bookings: any,
  setFilteredBookings: any,
  year: any
) => {
  const yearNumber = Number(year);

  if (isNaN(yearNumber)) {
    console.log("Invalid year");
    return;
  }

  const filtered = bookings.filter((booking: any) => {
    const bookingYear = new Date(booking.booking_date).getFullYear();
    return bookingYear === yearNumber;
  });

  setFilteredBookings(filtered);
};
