import React from "react";
import { FormInput, FormLabel } from "../../../base-components/Form";
import Button from "../../../base-components/Button";

export const SearchByYear = ({
  year,
  setYear,
  handleSearch,
  bookings,
  setFilteredBookings,
}) => {
  return (
    <div className="flex-row col-span-6 md:col-span-6 grid grid-cols-6 md:grid-cols-12 gap-3">
      <div className="col-span-3  ">
        <FormLabel htmlFor="year">Search By Year</FormLabel>
        <FormInput
          type="number"
          id="year"
          name="year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          placeholder="Enter year"
        />
      </div>

      <div className="col-span-2 mt-7 mb-5">
        <Button
          className="bg-primary  px-3 py-2 rounded-md text-white"
          onClick={() => {
            handleSearch(bookings, setFilteredBookings, year);
          }}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchByYear;
