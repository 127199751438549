import { useEffect, useState } from "react";

import Loading from "../../../components/Loading";
import { NavLink, useLocation } from "react-router-dom";
// import Pagination from "../../../components/Pagination";
import axios from "axios";

import { dateFormat } from "../../../helper/dateFormat";
import Table from "../../../base-components/Table";
import calculateAdminCommission from "../../../helper/calculateCommission";
import mergeBookingsBasedOnBookingId from "../../../helper/mergeBookingsBasedOnBookingId";
import filterBookingsHandler from "../../../helper/filterBookings";
import SearchFilters from "../../../custom-components/SearchFilters";
import PaymentTable from "../../../custom-components/Finance/PaymentTable";
import Heading from "../../../custom-components/Finance/Heading";
import { useDecodedToken } from "../../../hooks/useDecodedToken";

function Payments() {
  console.log("transport finance");
  const [loading, setLoading] = useState<boolean>(false);
  const [sortByValue, setSortByValue] = useState([]);
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();

  const [bookings, setBookings] = useState<any>();
  const [filteredBookings, setFilteredBookings] = useState<any>();

  let final_totalPrice = 0;
  let final_our_commission = 0;
  let final_debit = 0;
  let final_credit = 0;

  const decodedToken = useDecodedToken();

  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);

  // Get a specific query parameter
  const date = params.get("date");

  useEffect(() => {
    if (!date || !bookings) return;

    const d = new Date(date);

    const m = d.getMonth() + 1;
    console.log("m", m);
    setMonth(m);

    const filteredBookings = filterBookingsHandler(
      bookings,
      dateFrom,
      dateTo,
      m,
      year
    );
    setFilteredBookings(filteredBookings);
  }, [date, bookings]);

  useEffect(() => {
    if (!decodedToken) return console.log("decodedToken is null");
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/trip_bookings?userId=${decodedToken?.id}`
        );
        if (response.status === 200) {
          console.log("response data", response.data?.data);

          const mergedarray = mergeBookingsBasedOnBookingId(response.data.data);
          setBookings(mergedarray);
          setFilteredBookings(mergedarray);
          console.log("mergedarray", mergedarray);
        } else {
          console.error("Error in fetching transport bookings.");
        }
      } catch (error) {
        console.error("error: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [decodedToken]);

  const SearchFilterHandler = async () => {
    const filteredBookings = filterBookingsHandler(
      bookings,
      dateFrom,
      dateTo,
      month,
      year
    );
    setFilteredBookings(filteredBookings);
  };

  const ResetFilter = () => {
    setDateFrom("");
    setDateTo("");
    setMonth("");

    setYear("");
    setFilteredBookings(bookings);
  };
  let total_balance = 0;

  //paginnation
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = sortByValue?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const renderBookingList = (booking, index, balance) => {
    const admin_comission = calculateAdminCommission(
      booking?.total_price,
      parseFloat(booking?.our_commission || booking?.admin_comission)
    );
    return (
      <Table.Tr key={booking.id} className="text-center text-xs">
        <Table.Td>{dateFormat(booking.booking_date)}</Table.Td>
        <Table.Td style={{ color: "blue" }}>
          <NavLink to={`/view-transport-bookings/${booking.booking_id}`}>
            {booking.booking_id}{" "}
          </NavLink>
        </Table.Td>
        <Table.Td>{booking.total_price.toFixed(2)}</Table.Td>
        <Table.Td>{admin_comission.toFixed(2)}</Table.Td>
        <Table.Td>{admin_comission.toFixed(2)}</Table.Td>
        <Table.Td>
          {(booking.total_price - admin_comission).toFixed(2)}
        </Table.Td>
        <Table.Td>{balance.toFixed(2)}</Table.Td>
      </Table.Tr>
    );
  };
  return (
    <>
      {loading == true && <Loading />}
      <div className="container mx-auto">
        <Heading heading="Total Payment Report" />

        <SearchFilters
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
          ResetFilter={ResetFilter}
          onSearchFilter={SearchFilterHandler}
        />

        <div className="overflow-x-auto">
          <PaymentTable
            bookings={filteredBookings}
            renderBookingList={renderBookingList}
            final_totalPrice={final_totalPrice}
            final_our_commission={final_our_commission}
            final_debit={final_debit}
            final_credit={final_credit}
          />
          {/* {(currentItems?.length < ITEMS_PER_PAGE || currentPage >= 1) && (
            <Pagination
              currentPage={currentPage}
              totalItems={paymentData.length}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )} */}
        </div>
      </div>
    </>
  );
}

export default Payments;
