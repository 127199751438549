import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { icons } from "../base-components/Lucide";
import { jwtDecode } from "jwt-decode";

export interface Menu {
  icon: keyof typeof icons;
  title: string;
  pathname?: string;
  subMenu?: Menu[];
  ignore?: boolean;
}

export interface SideMenuState {
  menu: Array<Menu | "divider">;
}

const transportMenu: Array<Menu | "divider"> = [
  {
    icon: "Home",
    title: "Dashboard",
    pathname: "/",
  },
  {
    icon: "Truck",
    title: "Transport Type",
    pathname: "/transport-type",
  },
  {
    icon: "User",
    title: "Transport Driver",
    pathname: "/transport-drivers",
  },
  {
    icon: "Globe",
    title: "Bookings",
    subMenu: [
      {
        icon: "Link",
        pathname: "/transport_bookings",
        title: "Transport Bookings",
      },
    ],
  },
  {
    icon: "Landmark",
    title: "Finance",
    subMenu: [
      {
        icon: "Landmark",
        pathname: "/payments",
        title: "Total Payments",
      },
       {
        icon: "Landmark",
        pathname: "/receive_from_admin",
        title: "Recieve From Admin",
      },
    ],
  },
];

const carRentalMenu: Array<Menu | "divider"> = [
  {
    icon: "Home",
    title: "Dashboard",
    pathname: "/",
  },
  {
    icon: "Globe",
    title: "Car Rental",
    subMenu: [
      {
        icon: "Link",
        pathname: "/car-rental",
        title: "Manage Cars",
      },
      {
        icon: "Link",
        pathname: "/car-category",
        title: "Categories",
      },
    ],
  },
  {
    icon: "Globe",
    title: "Bookings",
    subMenu: [
      {
        icon: "Link",
        pathname: "/car_rental-bookings",
        title: "Car Rental booking",
      },
    ],
  },
  {
    icon: "Landmark",
    title: "Finance",
    subMenu: [
      {
        icon: "Landmark",
        pathname: "/payments",
        title: "Total Payments",
      },
      {
        icon: "Landmark",
        pathname: "/receive_from_admin",
        title: "Recieve From Admin",
      },
    ],
  },
];

let AuthToken = localStorage.getItem("doyoofToken");
console.log('AuthToken?.split(" ")[1]', AuthToken);
const decodedToken: any = AuthToken ? jwtDecode(AuthToken?.split(" ")[1] || "") : "";
let role = decodedToken.role; 

const initialState: SideMenuState = {
  menu: role === "CarRental" ? carRentalMenu : transportMenu,
};

export const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState,
  reducers: {},
});

export const selectSideMenu = (state: RootState) => state.sideMenu.menu;

export default sideMenuSlice.reducer;
