import { FormInput, FormLabel, FormSelect } from "../base-components/Form";
import Button from "../base-components/Button";

interface SearchFilterProps {
  dateFrom: string | undefined;
  setDateFrom: React.Dispatch<React.SetStateAction<string | undefined>>;
  dateTo: string | undefined;
  setDateTo: React.Dispatch<React.SetStateAction<string | undefined>>;
  month: number | undefined;
  setMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
  year: number | undefined;
  setYear: React.Dispatch<React.SetStateAction<number | undefined>>;
  ResetFilter: () => void;
  onSearchFilter: () => void;
}

const SearchFilters: React.FC<SearchFilterProps> = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  month,
  setMonth,
  year,
  setYear,
  ResetFilter,
  onSearchFilter,
}) => {
  return (
    <div className="flex-row col-span-6 md:col-span-6 grid grid-cols-6 md:grid-cols-12 gap-3">
      <div className="col-span-3">
        <FormLabel htmlFor="dateFrom">Search By Date From</FormLabel>
        <FormInput
          type="date"
          id="dateFrom"
          name="dateFrom"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          placeholder="Enter Date"
        />
      </div>
      <div className="col-span-3">
        <FormLabel htmlFor="dateTo">Search By Date To</FormLabel>
        <FormInput
          type="date"
          id="dateTo"
          name="dateTo"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          placeholder="Enter Date"
        />
      </div>

      <div className="col-span-3">
        <FormLabel htmlFor="month">Search By Month</FormLabel>
        <FormSelect
          id="month"
          name="month"
          value={month}
          onChange={(e) => setMonth(parseInt(e.target.value))}
        >
          <option value="">Select Month</option>
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString("default", { month: "long" })}
            </option>
          ))}
        </FormSelect>
      </div>
      <div className="col-span-3">
        <FormLabel htmlFor="year">Search By Year</FormLabel>
        <FormInput
          type="number"
          id="year"
          name="year"
          value={year || ""}
          onChange={(e) => setYear(Number(e.target.value))}
          placeholder="Enter year"
        />
      </div>

      <div className="col-span-2 mt-6 mb-5">
        <Button
          className="bg-warning px-3 py-2 mr-2 rounded-md text-white"
          onClick={ResetFilter}
        >
          Reset
        </Button>
        <Button
          className="bg-primary px-3 py-2 rounded-md text-white"
          onClick={onSearchFilter}
        >
          Search
        </Button>
      </div>
    </div>
  );
};


export default SearchFilters