import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthHook = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let AuthToken = localStorage.getItem("doyoofToken");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/admin/session`, {
        headers: {
          Authorization: AuthToken,
        },
      })
      .then((response: AxiosResponse) => {
        console.log(response);
        setIsLoggedIn(response.status === 200);
        if (response.status !== 200) navigate("/login");
      })
      .catch((error: AxiosError) => {
        console.log(error);
        setIsLoggedIn(false);
        navigate("/login");
      });
  }, []);

  return isLoggedIn;
};

export default AuthHook;
