import React from "react";

const Heading = ({ heading }: any) => {
  return (
    <div className="flex flex-col md:flex-row justify-between md:items-center my-4">
      <div className="title">
        <h1 className="text-2xl font-bold mb-4 text-center md:text-start">
          {heading}
        </h1>
      </div>
    </div>
  );
};  

export default Heading;
