interface StatusPercentages {
  booked: number;
  canceled: number;
  pending: number;
}

const calculateStatusPercentages = (bookings: any[]): StatusPercentages => {
  const statusCount: any = {
    booked: 0,
    canceled: 0,
    pending: 0,
  };

  bookings.forEach((booking) => {
    if (statusCount.hasOwnProperty(booking.booking_status)) {
      statusCount[booking.booking_status]++;
    }
  });

  const totalCount = bookings.length;
  const statusPercentages = {
    booked: (statusCount.booked / totalCount) * 100,
    canceled: (statusCount.canceled / totalCount) * 100,
    pending: (statusCount.pending / totalCount) * 100,
  };

  return statusPercentages;
};

export default calculateStatusPercentages;
