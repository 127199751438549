import React, { useEffect, useState } from "react";
import { FormInput, FormLabel } from "../../base-components/Form";
import axios from "axios";
import Button from "../../base-components/Button";
import { Dialog } from "../../base-components/Headless";

interface AdditionalPrice {
  id: number;
  additional_price_name: string;
  additional_price: number;
  additional_type: string;
}

const AdditionalPrice: React.FC<any> = ({
  setMeta,
  selectedAdditionalPrices,
  setSelectedAdditionalPrices,
  mode,
}) => {
  const isViewPage = mode === "view";
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [newAdditionalPrice, setNewAdditionalPrice] = useState<AdditionalPrice>(
    {
      id: 0,
      additional_price_name: "",
      additional_price: 0,
      additional_type: "",
    }
  );
  const [additionalPrices, setAdditionalPrices] = useState<AdditionalPrice[]>(
    []
  );
  //   const [selectedAdditionalPrices, setSelectedAdditionalPrices] = useState<
  //     AdditionalPrice[]
  //   >([]);

  useEffect(() => {
    console.log("selectedAdditionalPrices", selectedAdditionalPrices);
  }, [selectedAdditionalPrices]);
  const fetchAdditionalPrices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/car-rental/additional_price`
      );
      console.log("ADDITIONAL PRICE", response);
      if (response.ok) {
        const data = await response.json();
        setAdditionalPrices(data);
      } else {
        console.error(
          "Failed to fetch additional prices:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Failed to fetch additional prices:", error);
    }
  };

  const addAdditionalPrice = async () => {
    // setLoading(true);
    try {
      await axios.post<AdditionalPrice>(
        `${process.env.REACT_APP_BACKEND_URL}/car-rental/additional_price`,
        newAdditionalPrice
      );
      setAdditionalPrices([...additionalPrices, newAdditionalPrice]);
      setShowAddModal(false);
      setNewAdditionalPrice({
        id: 0,
        additional_price_name: "",
        additional_price: 0,
        additional_type: "",
      });
      //   setLoading(false);
    } catch (error) {
      console.error("Failed to add additional price:", error);
      //   setLoading(false);
    }
  };

  const handleCheckboxChange = (price: any) => {
    if (selectedAdditionalPrices.some((item: any) => item.id === price.id)) {
      setSelectedAdditionalPrices(
        selectedAdditionalPrices.filter((p: any) => p.id !== price.id)
      );
    } else {
      setSelectedAdditionalPrices([...selectedAdditionalPrices, price]);
      // Update additionalLabel, additionalPrice, and additionalType in the meta state
      console.log("HE");
      //   setMeta((prevMeta) => ({
      //     ...prevMeta,
      //     additionalLabel: prevMeta.additionalLabel
      //       ? prevMeta.additionalLabel + "," + price.additional_price_name
      //       : price.additional_price_name,
      //     additionalPrice: prevMeta.additionalPrice
      //       ? prevMeta.additionalPrice + "," + price.additional_price
      //       : price.additional_price,
      //     additionalType: prevMeta.additionalType
      //       ? prevMeta.additionalType + "," + price.additional_type
      //       : price.additional_type,
      //   }));
    }
  };
  useEffect(() => {
    // Fetch facilities data
    fetchAdditionalPrices();
  }, []);
  return (
    <>
      <div className="col-span-6 md:col-span-6  title my-8 col-span-6">
        <div className=" text-2xl text-gray-600 font-bold">
          Additional Prices
        </div>
        <div className="mutted w-full text-xs text-mutted text-gray-400">
          Please fill the required fields
        </div>
      </div>
      <div className="col-span-6 md:col-span-6 p-4 border w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
        <div className="col-span-6 my-3">
          <div className="flex flex-wrap gap-4 mt-2">
            {additionalPrices.map((price: any) => (
              <label
                key={price.id}
                className="inline-flex pe-4 items-center text-gray-500"
              >
                <input
                  type="checkbox"
                  name="additionalPrices"
                  value={price.id}
                  checked={selectedAdditionalPrices.some(
                    (item: any) => item.id === price.id
                  )}
                  onChange={() => handleCheckboxChange(price)}
                  disabled={isViewPage}
                />
                <span className="ml-2">
                  {price.additional_price_name} - {price.additional_price}
                  {price.additional_type == "percentage" && " %"}
                </span>
              </label>
            ))}
          </div>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowAddModal(true);
            }}
            className="text-blue-600 w-full mt-5"
          >
            Add New Items
          </p>
        </div>

        {/* Render additional fields for selected additional prices */}
        {selectedAdditionalPrices.map((price: any) => (
          <div key={price.id}>
            <FormLabel htmlFor="`label_${price.id}`">
              Additional Label:
            </FormLabel>
            <FormInput
              className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
              type="text"
              id={`label_${price.id}`}
              value={price.additional_price_name}
              readOnly
              disabled={isViewPage}
            />

            <FormLabel htmlFor={`price_${price.id}`}>
              Additional Price:
            </FormLabel>

            <FormInput
              className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
              type="text"
              id={`price_${price.id}`}
              value={price.additional_price}
              readOnly
            />
            <FormLabel htmlFor={`type_${price.id}`}>Additional Type:</FormLabel>
            <FormInput
              className="form-control border border-gray-200 rounded px-3 py-2 mt-1 shadow-sm  focus:outline-none focus:border-blue-500"
              type="text"
              id={`type_${price.id}`}
              value={price.additional_type}
              readOnly
            />
          </div>
        ))}

        <Dialog
          size="md"
          open={showAddModal}
          onClose={() => {
            setShowAddModal(false);
            // setEditFacilityId(null); // Reset edit facility ID when closing the dialog
            // setNewFacilityName(''); // Clear new facility name input when closing the dialog
          }}
          className={"pt-4"}
        >
          <Dialog.Panel className={"top-12 py-4 px-6"}>
            <div className="text-3xl font-bold p-5 text-center">
              Add Additional Price
            </div>
            <hr />
            <div className="flex flex-col gap-4 mt-5">
              <h2 className="text-2xl font-bold mb-4">Add Additional Price</h2>
              <input
                type="text"
                placeholder="Name"
                value={newAdditionalPrice.additional_price_name}
                onChange={(e) =>
                  setNewAdditionalPrice({
                    ...newAdditionalPrice,
                    additional_price_name: e.target.value,
                  })
                }
                className="border border-gray-300 rounded-md w-full mb-4 p-2"
                disabled={isViewPage}
              />
              <input
                type="number"
                placeholder="Price"
                value={newAdditionalPrice.additional_price}
                onChange={(e) =>
                  setNewAdditionalPrice({
                    ...newAdditionalPrice,
                    additional_price: parseInt(e.target.value) || 0,
                  })
                }
                className="border border-gray-300 rounded-md w-full mb-4 p-2"
                disabled={isViewPage}
              />
              <select
                value={newAdditionalPrice.additional_type}
                onChange={(e) =>
                  setNewAdditionalPrice({
                    ...newAdditionalPrice,
                    additional_type: e.target.value,
                  })
                }
                className="border border-gray-300 rounded-md w-full mb-4 p-2"
              >
                <option value="">Select Type</option>
                <option value="regular">Regular</option>
                <option value="percentage">Percentage</option>
              </select>
              <div className="flex justify-center mt-6 text-white">
                <Button className="bg-primary" onClick={addAdditionalPrice}>
                  Add
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </>
  );
};

export default AdditionalPrice;
