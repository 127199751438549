import { useEffect, useState } from "react";
import axios from "axios";

const useFetch = (endpoint: string) => {
  const [dataState, setDataState] = useState({
    data: null,
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`
        );

        console.log(response.data);
        if (response.status === 200) {
          setDataState({
            data: response.data.data,
            isLoading: false,
            isError: false,
          });
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);

        setDataState({
          data: null,
          isLoading: false,
          isError: true,
        });
      }
    };

    fetchApiData();
  }, [endpoint]);

  return [dataState.data, dataState.isLoading, dataState.isError];
};

export default useFetch;
