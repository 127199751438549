import React from "react";
import Table from "../../../base-components/Table";
import { capitalizeFirstLetter } from "../../../utils/helper";

const DataTable = ({ bookings, months, monthlyTotals }) => {
  return (
    <Table>
      <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
        <Table.Tr>
          <Table.Th className="whitespace-nowrap">Sr#</Table.Th>
          <Table.Th className="whitespace-nowrap">Month</Table.Th>

          <Table.Th className="whitespace-nowrap">Year</Table.Th>
          <Table.Th className="whitespace-nowrap">Amount</Table.Th>
          <Table.Th className="whitespace-nowrap ">Paid Status</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {bookings?.length === 0 ? (
          <Table.Tr>
            <Table.Td colSpan={6} className="text-center">
              Currently No Payments Receive From Admin
            </Table.Td>
          </Table.Tr>
        ) : (
          months?.map((month, index) => {
            console.log("is empty?", monthlyTotals[month].total.toFixed(1));
            if (!parseInt(monthlyTotals[month].total)) {
              console.log("empty");
              return;
            }
            return (
              // <p key={month}>{month}: {monthlyTotals[month].toFixed(2)}</p>

              <Table.Tr key={index + 1} className="text-center text-xs">
                <Table.Td>{index + 1}</Table.Td>

                <Table.Td>{month}</Table.Td>
                <Table.Td>{monthlyTotals[month].year}</Table.Td>
                <Table.Td>{monthlyTotals[month].total.toFixed(1)}</Table.Td>
                <Table.Td
                  className={
                    monthlyTotals[month].status === "paid"
                      ? "text-success font-bold"
                      : "text-danger font-bold"
                  }
                >
                  {capitalizeFirstLetter(monthlyTotals[month].status)}
                </Table.Td>
              </Table.Tr>
            );
          })
        )}
      </Table.Tbody>
    </Table>
  );
};

export default DataTable;
