import Table from "../../../base-components/Table";
import { useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import Loading from "../../../components/Loading";
import { dateFormat } from "../../../helper/dateFormat.js";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import DriversList from "./DriversList";
import toast from "react-hot-toast";
import { useDecodedToken } from "../../../hooks/useDecodedToken";

function TransportBookings() {
  const [bookingsData, setBookingsData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assignRowId, setAssignRowId] = useState(null);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const decodedToken = useDecodedToken();
  const location = useLocation();
  console.log("location", location);


  useEffect(() => {
    if (!decodedToken) return console.log("decodedToken is null");
    const fetchApiData = async () => {
      try {
          if (location?.state?.bookings) {
            console.log('redirected')
            return setBookingsData(location?.state?.bookings);
          }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/trip_bookings?userId=${decodedToken?.id}`
        );

        console.log(response.data);
        if (response.status === 200) {
          console.log(response.data.data);
          setBookingsData(response.data.data);
        } else {
          throw new Error("Error in API fetching");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchApiData();
  }, [decodedToken, location?.state?.bookings]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const assignDriver = async (email, booking_id, driver_id, code) => {
    console.log("EMAIL: ", email);
    // setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/assign-driver`,
        { id: booking_id, email, driver_id, code }
      );
      if (response.status === 200) {
        toast.success("Driver assigned successfully");
      } else {
        throw new Error("Assignment failed");
      }
    } catch (error) {
      console.error("Assignment error:", error);
      toast.error("Failed to assign driver.");
    }
    // setLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (selectedDriverId && assignRowId) {
      const uniqueCode = Math.random().toString(36).substr(2, 7).toUpperCase();

      const customerEmail = bookingsData.find(
        (booking) => booking.id === assignRowId
      ).email;
      console.log("customerEmail", customerEmail);
      await assignDriver(
        customerEmail,
        assignRowId,
        selectedDriverId,
        uniqueCode
      );

      setBookingsData(
        bookingsData.map((booking) =>
          booking.id === assignRowId
            ? { ...booking, driver_id: selectedDriverId, code: uniqueCode }
            : booking
        )
      );

      // Resetting state after updating
      setAssignRowId(null);
      setSelectedDriverId(null);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {loading == true && <Loading />}
      <div className="container mx-auto">
        <Modal
          title="Assign Driver"
          open={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{}}
          closeIcon={<CloseOutlined />}
        >
          <div style={{ padding: "20px" }}>
            <DriversList
              onSelectedDriverId={setSelectedDriverId}
              triggerRerender={Math.random()}
              assignRowId={assignRowId}
              bookingsData={bookingsData}
              setBookingsData={setBookingsData}
              setIsModalVisible={setIsModalVisible}
            />
          </div>
        </Modal>
        <div className="flex flex-col md:flex-row justify-between md:items-center my-4">
          <div className="title">
            <h1 className="text-2xl font-bold mb-4 text-center md:text-start">
              Transport Bookings
            </h1>
          </div>
        </div>

        <div className="overflow-x-auto">
          <Table hover>
            <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
              <Table.Tr>
                <Table.Th className="whitespace-nowrap">#</Table.Th>
                <Table.Th className="whitespace-nowrap">Booking Id</Table.Th>
                <Table.Th className="whitespace-nowrap">Booking Date</Table.Th>
                <Table.Th className="whitespace-nowrap">Total Amount</Table.Th>
                <Table.Th className="whitespace-nowrap">Paid Status</Table.Th>
                <Table.Th className="whitespace-nowrap">
                  Booking Status
                </Table.Th>
                <Table.Th className="whitespace-nowrap">Details</Table.Th>
                <Table.Th className="whitespace-nowrap">Assign Driver</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {bookingsData?.length === 0 ? (
                <Table.Tr>
                  <Table.Td colSpan={6} className="text-center">
                    Currently No Bookings Available
                  </Table.Td>
                </Table.Tr>
              ) : (
                bookingsData.map((booking, index) => {
                  return (
                    <Table.Tr key={booking.id} className="text-center text-xs">
                      <Table.Td>{index + 1}</Table.Td>
                      <Table.Td>{booking.booking_id}</Table.Td>
                      <Table.Td>{dateFormat(booking.booking_date)}</Table.Td>
                      <Table.Td>{booking.total_price}</Table.Td>
                      <Table.Td>{booking.paid_status}</Table.Td>
                      <Table.Td>
                        <span className="bg-warning p-1 rounded-md text-white">
                          {booking.booking_status}
                        </span>
                      </Table.Td>
                      <Table.Td className="flex justify-center items-center">
                        <NavLink
                          to={`/view-transport-booking-details/${booking.booking_id}`}
                          state={booking}
                        >
                          <Button className="bg-primary p-3 rounded-md text-white">
                            View
                          </Button>
                        </NavLink>
                      </Table.Td>

                      <Table.Td>
                        {booking?.driver_id ? (
                          <span className="text-green-500 font-semibold px-4 py-2">
                            Assigned
                          </span>
                        ) : (
                          <Button
                            onClick={() => {
                              setAssignRowId(booking.id);
                              showModal();
                            }}
                            className="bg-primary p-2 rounded-md text-white"
                          >
                            Assign
                          </Button>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  );
                })
              )}
            </Table.Tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default TransportBookings;
