import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  FormInput,
  FormLabel,
  FormTextarea,
} from "../../../base-components/Form";

import { PreviewComponent } from "../../../base-components/PreviewComponent";

import CustomFormInput from "../../../custom-components/CustomFormInput";
import Loading from "../../../components/Loading";
import Table from "../../../base-components/Table";
import { dateFormat } from "../../../helper/dateFormat";
import axios from "axios";
import { useDecodedToken } from "../../../hooks/useDecodedToken";

const BookingDetails = () => {
  const { bookingId } = useParams();

  console.log("bookingId", bookingId);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const decodedToken = useDecodedToken();

  useEffect(() => {
    if (!decodedToken) return console.log("decodedToken is null");
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/trip_bookings?userId=${decodedToken?.id}&bookingId=${bookingId}`
        );
        if (response.status === 200) {
          console.log("response data", response.data?.data);

          setBookingDetails(response.data?.data);
        } else {
          console.error("Error in fetching transport bookings.");
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [decodedToken]);

  console.log("bookingDetails :", bookingDetails);

  const renderBookingDetails = (booking: any) => {
    const { transport_type, pick_up_location, drop_off_location } = booking;
    const { additional_price }: any = transport_type;

    const totalAdditionalPrice = additional_price.reduce(
      (total: any, item: any) => {
        return total + parseInt(item.price);
      },
      0
    );

    const summedPrices = additional_price.reduce((acc: any, item: any) => {
      let { type, price, label } = item;
      price = parseFloat(price);

      if (!acc[type]) {
        acc[type] = {};
      }

      if (!acc[type][label]) {
        acc[type][label] = 0;
      }

      acc[type][label] += price;

      return acc;
    }, {});

    const percentagePrice = summedPrices.percentage;
    const regularPrice = summedPrices.regular;
    return (
      <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm my-5">
        <div className="col-span-6 md:col-span-3">
          <FormLabel className="text-gray-500" htmlFor="hotelName">
            Label
          </FormLabel>
          <FormInput
            type="text"
            id="label"
            name="label"
            value={transport_type.label}
            disabled={true}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <FormLabel className="text-gray-500" htmlFor="bags">
            Luggage
          </FormLabel>
          <FormInput
            type="number"
            min={0}
            id="bags"
            name="bags"
            value={transport_type.bags}
            disabled={true}
          />
        </div>

        <div className="col-span-4 md:col-span-2">
          <FormLabel className="text-gray-500" htmlFor="doors">
            Doors
          </FormLabel>
          <FormInput
            type="number"
            id="doors"
            name="doors"
            value={transport_type.doors}
            disabled={true}
          />
        </div>

        <div className="col-span-4 md:col-span-2">
          <FormLabel className="text-gray-500" htmlFor="people">
            People
          </FormLabel>
          <FormInput
            type="number"
            id="people"
            name="people"
            value={transport_type.people}
            disabled={true}
          />
        </div>

        <div className="col-span-4 md:col-span-2">
          <FormLabel className="text-gray-500" htmlFor="seats">
            Seats
          </FormLabel>
          <FormInput
            type="number"
            id="seats"
            name="seats"
            value={transport_type.seats}
            disabled={true}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <FormLabel className="text-gray-500" htmlFor="ac">
            AC
          </FormLabel>
          <FormInput
            id="ac"
            name="ac"
            value={transport_type.ac ? "true" : "false"}
            disabled={true}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <FormLabel className="text-gray-500" htmlFor="auto">
            Auto
          </FormLabel>
          <FormInput
            id="auto"
            name="auto"
            value={transport_type.auto ? "auto" : "manual"}
            disabled={true}
          />
        </div>
        <CustomFormInput
          label="Pickup Location"
          name="pickup_location"
          type="text"
          value={pick_up_location?.place}
          disabled={true}
        />
        <CustomFormInput
          label="Drop off Location"
          name="dropoff_location"
          type="text"
          value={drop_off_location?.place}
          disabled={true}
        />
        <CustomFormInput
          label="Trip Date"
          name="trip_date"
          type="text"
          value={dateFormat(booking?.trip_date)}
          disabled={true}
        />

        <div className="col-span-6 mt-3">
          <FormLabel className="text-gray-500" htmlFor="description">
            Description
          </FormLabel>

          <FormTextarea
            id="description"
            name="description"
            rows={3}
            value={transport_type.description}
            disabled={true}
          />

          <div className="col-span-12 md:col-span-12">
            <div className="overflow-x-auto">
              <Table hover className="w-full mt-5">
                <Table.Thead className="text-center bg-primary rounded-2xl text-gray-100">
                  <Table.Tr>
                    <Table.Th className="whitespace-nowrap">
                      Price per KM
                    </Table.Th>
                    <Table.Th className="whitespace-nowrap">
                      Admin Commission
                    </Table.Th>
                    <Table.Th className="whitespace-nowrap">
                      Additional Price
                    </Table.Th>
                    <Table.Th className="whitespace-nowrap">
                      Total Amount
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr className="text-center text-xs">
                    <Table.Td>{transport_type?.price_per_km}</Table.Td>
                    <Table.Td>{booking?.our_commission}</Table.Td>
                    <Table.Td>
                      {/* {totalAdditionalPrice} {additional_price[0].label} */}
                      {Object.keys(percentagePrice || {})?.map((key, idx) => {
                        return (
                          <div>
                            {percentagePrice[key]}% - {key}
                          </div>
                        );
                      })}

                      {Object.keys(regularPrice || {}).map((key, idx) => {
                        return (
                          <div>
                            {percentagePrice[key]} SAR - {key}
                          </div>
                        );
                      })}
                    </Table.Td>
                    <Table.Td>{booking?.total_price}</Table.Td>
                  </Table.Tr>
                  {/* );
                      })} */}
                </Table.Tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading === true && <Loading />}
      <PreviewComponent className="intro-y box">
        <div className={"flex flex-col items-center py-5 px-8 divide-y"}>
          <h1 className="text-2xl font-bold my-4">Complete Booking Details</h1>
        </div>
        <div className={"flex flex-col items-center py-5 px-8"}>
          <div className="col-span-6 md:col-span-6 w-full grid grid-cols-6 gap-3 p-5 border-blue-500 border-2 rounded-lg shadow-sm">
            <CustomFormInput
              label="Booking Id"
              name="booking_id"
              type="text"
              value={bookingDetails.length > 0 && bookingDetails[0].booking_id}
              disabled={true}
            />

            <CustomFormInput
              label="Booking Date"
              name="booking_date"
              type="text"
              value={
                bookingDetails.length > 0 &&
                dateFormat(bookingDetails[0].booking_date)
              }
              disabled={true}
            />
            <CustomFormInput
              label="Paid Status"
              name="paid_status"
              type="text"
              value={
                bookingDetails.length > 0 && bookingDetails[0]?.paid_status
              }
              disabled={true}
            />
            <CustomFormInput
              label="Transport User Id"
              name="transport_user_id"
              type="text"
              value={
                bookingDetails.length > 0 &&
                bookingDetails[0]?.transport_user_id
              }
              disabled={true}
            />
            <CustomFormInput
              label="Customer Email"
              name="email"
              type="text"
              value={bookingDetails.length > 0 && bookingDetails[0]?.email}
              disabled={true}
            />
          </div>

          <div className="title my-3 w-full col-span-6">
            <div className=" text-2xl text-gray-600 font-bold mt-4">
              Trip Details
            </div>
          </div>

          {bookingDetails.length > 0 &&
            bookingDetails?.map((booking, index) =>
              renderBookingDetails(booking)
            )}
        </div>
      </PreviewComponent>
    </>
  );
};

export default BookingDetails;
