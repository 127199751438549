import React from "react";

const MainTitle = ({ title }: any) => {
  return (
    <div className="title">
      <h1 className="text-2xl font-bold mb-4 text-center md:text-start">
        {title}
      </h1>
    </div>
  );
};

export default MainTitle;
