import calculateAdminCommission from "../../helper/calculateCommission";

interface TotalPayments {
  totalPrice: number;
  ourCommission: number;
  debit: number;
  credit: number;
  balance: number;
}

const calculateCurrentMonthTotals = (
  bookings: any,
  date: any
): TotalPayments => {
  //   const date = new Date();
  date = new Date(date);
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const monthlyBookings = bookings.filter((booking) => {
    const bookingDate = new Date(booking.booking_date);
    return (
      bookingDate.getMonth() === currentMonth &&
      bookingDate.getFullYear() === currentYear
    );
  });

  let totalPrice = 0;
  let ourCommission = 0;
  let debit = 0;
  let credit = 0;

  monthlyBookings.forEach((booking) => {
    const totalWithAdditionalPrice = booking.total_price;
    const adminCommission = calculateAdminCommission(
      parseFloat(booking?.our_commission || booking?.admin_comission),
      totalWithAdditionalPrice
    );
    const currentCredit = totalWithAdditionalPrice - adminCommission;

    totalPrice += totalWithAdditionalPrice;
    ourCommission += adminCommission;
    debit += adminCommission;
    credit += currentCredit;
  });

  return {
    totalPrice,
    ourCommission,
    debit,
    credit,
    balance: credit - debit,
  };
};

export default calculateCurrentMonthTotals;
